<template>
    <header
        :class="className"
        @mouseenter="isHidden = false"
    >
        <div class="l-header__inner">
            <router-link
                to="/"
                title="Groupe Boucher"
                class="l-header__logo"
            >
                <icon icon="logo-gb" />
            </router-link>
            <btn-close
                class="l-header__trigger"
                @click.native="toggleNav()"
            >
                <span class="o-btn-close__text -open">Menu</span>
                <span class="o-btn-close__text -close">Fermer</span>
            </btn-close>
            <nav
                class="l-nav"
                itemscope
                itemtype="http://schema.org/SiteNavigationElement"
                :style="`--header-links-count: ${nav.length}`"
            >
                <ul class="l-nav__list">
                    <li
                        v-for="(item, i) in nav"
                        :key="i"
                        class="l-nav__item"
                        itemprop="name"
                    >
                        <router-link
                            :to="item.path"
                            :title="item.entry.title"
                            class="l-nav__link"
                            exact
                            itemprop="url"
                        >
                            {{ item.label ? item.label : item.entry.title }}
                        </router-link>
                    </li>
                </ul>
            </nav>
        </div>
    </header>
</template>

<script>

import Icon from 'objects/Icon';
import BtnClose from 'objects/BtnClose';

export default {
    name: 'AppHeader',
    components: {
        Icon,
        BtnClose,
    },
    data: () => ({
        navIsOpen: false,
        isHidden: false,
    }),
    mounted() {

        // Bind window scroll event
        let scroll
        let prevScroll = 0
        const scrollDelta = 20
        window.addEventListener('scroll', () => {
            scroll = window.scrollY

            if(this.navIsOpen) {
                this.closeNav()
            }

            if(scroll <= 0) {
                this.isHidden = false
                prevScroll = 0
            } else if(scroll < prevScroll) {
                this.isHidden = false
                prevScroll = scroll
            } else if(scroll > prevScroll + scrollDelta) {
                this.isHidden = true
                prevScroll = scroll
            }

        })
    },
    computed: {
        className() {
            let classname = 'l-header'

            if(this.isHidden) {
                classname += ` is-hidden`
            }

            return classname
        },
        nav() {
            return this.$store.state.global.nav
        }
    },
    methods: {
        openNav() {
            this.navIsOpen = true

            // this.currentScroll = window.scrollY
            // document.documentElement.style.overflowY = 'hidden';
        },
        closeNav() {
            this.navIsOpen = false

            // // Add previous scroll
            // document.documentElement.style.overflowY = 'scroll';
            // window.scrollTo(0, this.currentScroll)
        },
        toggleNav() {
            if(this.navIsOpen) {
                this.closeNav()
            } else {
                this.openNav()
            }
        },
    },
    watch: {
        navIsOpen(isOpen) {
            this.$emit('toggleNav', isOpen)
        },
        $route() {
            this.closeNav()
        }
    }
};

</script>

<style lang="scss">

.l-header {
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    width: 100%;

    @media #{md("md")} {

        &.is-hidden {

            .l-header__inner {
                margin-top: calc(-1 * var(--header-height));
                //transform: translate(0, -100%);
            }
        }
    }
}

.l-header__inner {
    display: flex;
    height: var(--header-height);
    margin: 0;

    @media #{md("md")} {
        @include blurred-bg();
        //transition: transform .8s $out-expo;
        transition: margin .8s $out-expo;
    }
}

.l-header__logo {
    z-index: 1;
    display: flex;
    align-items: center;
    height: 100%;
    padding-right: 1.25em;
    padding-left: 1.25em;

    &:after {
        @include pseudo-el($width: 1px, $bg: $color-secondary);
        position: absolute;
        top: 0;
        right: 0;
        pointer-events: none;
    }

    // Fix logo cut
    .o-icon {
        padding-bottom: 1px;
    }
}

.l-header__trigger {
    z-index: 1;
    top: -.1em;
    display: flex;
    align-items: center;

    .o-btn-close__dot.-c {
        transform: scale(0);
    }

    .o-btn-close__text {

        &.-open {
            position: absolute;
            top: .15em;
            left: 0;
            width: 100%;
            text-align: center;
        }

        &.-close {
            opacity: 0;
        }
    }

    .nav-is-open & {

        .o-btn-close__dot {

            &.-tl {
                transform: translate(250%, 250%);
            }

            &.-tr {
                transform: translate(-250%, 250%);
            }

            &.-bl {
                transform: translate(250%, -250%);
            }

            &.-br {
                transform: translate(-250%, -250%);
            }

            &.-c {
                transform: scale(1);
            }
        }

        .o-btn-close__text {

            &.-open {
                opacity: 0;
            }

            &.-close {
                opacity: 1;
            }
        }
    }

    @media #{md("md")} {
        display: none;
        visibility: hidden;
    }
}

.l-nav {

    @media #{md("md", "max")} {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding-top: var(--header-height);
        height: 100%;
        transition: height .8s $out-expo;

        backface-visibility: hidden;
        pointer-events: none;

        @include blurred-bg($blurred: true);

        .nav-is-open & {
            height: calc( ( var(--header-links-count, 6) * 100%) + var(--header-height) - .5em );
            pointer-events: auto;
        }
    }

    @media #{md("md")} {
        height: 100%;
    }
}

.l-nav__list {
    @include reset-list;
    display: flex;

    &:hover {

        .l-nav__link {

            &:not(:hover) {
                color: rgba($color-dark, .2);

                &.is-active {
                    color: rgba($color-primary, .6);
                }
            }
        }
    }

    @media #{md("md", "max")} {
        flex-direction: column;
        padding-top: .5em;
        padding-bottom: .5em;
        opacity: 0;

        &:before {
            @include pseudo-el($height: 1px, $bg: $color-secondary);
            position: absolute;
            top: 0;
            left: 0;
            pointer-events: none;
            transform: scale(0);
        }

        .nav-is-open & {
            opacity: 1;
            transition: opacity .4s ease-out .4s;

            &:before {
                transform: scale(1);
                transition: transform .4s $out-quad .4s;
            }
        }
    }

    @media #{md("md")} {
        height: inherit;
        align-items: center;
        margin-right: 1em;
        margin-left: 1em;
    }
}

.l-nav__item {
}

.l-nav__link {
    display: block;
    transition: color .2s ease-out;

    &:hover {
        color: $color-dark;
    }

    &.is-active {
        color: $color-primary;
        pointer-events: none;
    }

    @media #{md("md", "max")} {
        padding: 1em 1.25em;
    }

    @media #{md("md")} {
        display: flex;
        align-items: center;
        height: var(--header-height);
        padding: .75em;
    }
}


/*==================================
=            Nav button            =
==================================*/





</style>
