import Vue from 'vue'
import store from 'src/store'

import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger);

import { EventBus } from 'src/event-bus'

const revealClass = new class Reveal {

    constructor() {
        // console.log(`${this.constructor.name}:init`)

        this.classname = {
            reveal: 'js-reveal',
            visible: 'is-visible',
        }

        this.items = {}

        this.isEnabled = false


        const $body = document.body
        let bodyHeight = 0
        let newHeight
        setInterval(() => {
            newHeight = $body.clientHeight

            // Return if same height
            if(bodyHeight === newHeight) {
                return
            }

            bodyHeight = newHeight

            EventBus.$emit('bodyHeightUpdate')
            this.refreshTriggers()

        }, 1000)

        // Watch load state to enable/disable reveals
        this.loaderWatch = store.watch(() => store.getters['loader/isLoading'], loading => {
            if (loading) {
                this.disable()
            } else {
                const delay = store.state.loader.firstload ? 1000 : 400
                setTimeout(this.enable.bind(this), delay);
            }
        })
    }

    refreshTriggers() {
        for(let id in this.items) {
            this.items[id].trigger.refresh(true)
        }
    }

    addTrigger(id, item) {
        // console.log(`${this.constructor.name}:addTrigger`)

        item.$el.classList.add(this.classname.reveal)

        this.items[id] = {
            id: item.id,
            $el: item.$el,
            cb: item.cb,
            once: item.once,
            trigger: ScrollTrigger.create({
                trigger: item.$el,
                start: 'top 90%',
                end: 'bottom 0',
                onUpdate: (self) => this.updateItem(id, self),
                once: item.once,
            })
        }

        if(!this.isEnabled) {
            this.items[id].trigger.disable()
        }
    }

    updateItem(id, state) {
        // console.log(`${this.constructor.name}:updateItem`, id, state)

        const item = this.items[id]

        if(!this.isEnabled || typeof item === 'undefined') {
            return
        }

        if (item.cb) {
            item.cb(state)
        }

        if(state.isActive) {
            item.$el.classList.add(this.classname.visible)
        } else {
            item.$el.classList.remove(this.classname.visible)
        }

        if(item.once) {
            this.removeTrigger(item.id)
        }

    }

    removeTrigger(id) {
        // console.log(`${this.constructor.name}:removeTrigger`)

        const item = this.items[id]

        if(typeof item === 'undefined') {
            return
        }

        item.trigger.kill()

        item.$el.classList.remove(this.classname.reveal)
        item.$el.classList.remove(this.classname.visible)

        delete this.items[id]
    }

    enable() {
        // console.log(`${this.constructor.name}:enable`)

        this.isEnabled = true

        let item
        for(let id in this.items) {
            item = this.items[id]
            item.trigger.enable()
            if(item.trigger.isActive) {
                this.updateItem(id, item.trigger)
            }
        }
    }

    disable() {
        // console.log(`${this.constructor.name}:disable`)

        for(let id in this.items) {
            this.items[id].trigger.disable()
        }

        this.isEnabled = false
    }

    destroy() {
        // console.log(`${this.constructor.name}:destroy`)

        EventBus.$off('bodyHeightUpdate', this.refreshTriggers)
        EventBus.$off('loaderStart')
        EventBus.$off('loaderEnd')
    }
}

let revealIndex = 0
//const revealTriggers = {}
const reveal = Vue.directive('reveal', {
    inserted: function($el, bind) {
        const arg = bind.value

        // Return if argument is set to false
        if(arg === false) {
            return
        }

        // Define item id
        let id = $el.dataset.reveal || revealIndex++

        // Set default item values
        let item = revealClass.items[id] || {
            id,
            $el,
            cb: typeof arg === 'function' ? arg : false,
            once: !!bind.modifiers.once,
        }

        $el.dataset.reveal = id

        revealClass.addTrigger(id, item)
    },
    unbind: function($el, bind) {

        // Return if argument is set to false
        if(bind.value === false) {
            return
        }

        const id = Number($el.dataset.reveal)

        revealClass.removeTrigger(id)
    }
})

export default reveal
