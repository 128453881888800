<template>
    <div v-if="cards.length > 0" :class="className">
        <div class="c-card-list__groups">
            <div v-for="(group, key) in groups" class="c-card-list__group" :key="`group-${key}`">
                <div class="c-card-list__group-title">
                    <h3 class="t-t4" v-html="group.groupTitle" :data-count="group.elements.length" />
                </div>
                <div class="c-card-list__group-items">
                    <card-job v-for="(item, i) in group.elements" :key="`store-${item.id}-${i}`" :data="item" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CardJob from "components/CardJob";

import Variables from "src/mixins/variables";

import { groupBy as _groupBy } from "underscore";

export default {
    name: "CardList",
    components: {
        CardJob,
    },
    mixins: [Variables],
    props: {
        cards: {
            type: Array,
            default: () => [],
        },
        groupBy: {
            type: Object,
            required: true,
        },
        orderBy: {
            type: String,
        },
    },
    data: () => ({}),
    created() {},
    mounted() {},
    computed: {
        className() {
            let classname = "c-card-list";

            return classname;
        },
        groups() {
            let _groups = _groupBy(this.cards, (card) => {
                    return card[this.groupBy.prop][this.groupBy.key];
                }),
                _output = _groups;

            if (this.orderBy) {
                const state = this.$store.state[this.orderBy];

                if (!state) {
                    // eslint-disable-next-line no-console
                    console.error("There's no store module named `" + this.orderBy + "`");
                } else {
                    // Reset _output
                    _output = {};

                    const orderSources = state.elements;
                    const orderKeys = orderSources.map((el) => el.slug);

                    orderKeys.forEach((key) => {
                        const matchingElement = orderSources.find((e) => e.slug === key);

                        if (_groups[key]) {
                            _output[key] = {
                                groupTitle: matchingElement.title,
                                elements: _groups[key],
                            };
                        }
                    });
                }
            }

            return _output;
        },
    },
    methods: {},
    beforeDestroy() {},
};
</script>

<style lang="scss">
.c-card-list {
    margin-top: var(--grid-gutter);

    &__groups {
        display: grid;
        gap: 4.5rem;

        @media #{md("md")} {
            gap: calc(var(--grid-gutter) * 2);
        }
    }

    &__group {
        display: grid;
        gap: var(--grid-gutter-half);

        @media #{md("md")} {
            gap: var(--grid-gutter);
            grid-template-columns: 1fr 3fr;
        }

        &-title {
            --offet-right: calc(1.5rem + var(--grid-gutter));
            padding-right: var(--offet-right);

            @media #{md("md", "max")} {
                position: sticky;
                top: 4.5rem;
                z-index: 1;

                h3 {
                    z-index: 2;
                }

                &:after {
                    content: "";
                    background: $color-light;
                    position: absolute;
                    left: -2px;
                    right: -2px;
                    bottom: -0.5rem;
                    top: -4.5rem;
                    z-index: 1;
                }
            }
            h3 {
                display: inline;

                &:after {
                    position: absolute;
                    display: inline-block;
                    content: attr(data-count);

                    min-width: 1.5rem;
                    padding: 0.301rem;

                    font-size: 0.9rem;
                    font-weight: 300;
                    line-height: 1em;
                    text-align: center;

                    background-color: $color-blue-lighter;
                    border-radius: $border-radius-xs;

                    margin-top: -0.2rem;
                    margin-left: 0.4rem;
                }
            }

            span {
                position: absolute;
                top: 0;
                right: var(--offet-right);
            }
        }
    }

    &__group-items {
        display: grid;
        gap: var(--grid-gutter);

        > * + * {
            padding-top: var(--grid-gutter);
            border-top: 1px solid $color-blue-light;
        }
    }
}
</style>
