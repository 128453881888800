<template>
    <component
        v-if="items && items.length > 0"
        :is="tag"
        :class="className"
    >
        <components
            v-for="(item, i) in items"
            :key="`list-${_uid}-${i}`"
            :is="tag === 'ul' || tag === 'ol' ? 'li' : 'div'"
            class="c-list__item"
        >
            <slot v-bind="item">
                {{ item }}
            </slot>
        </components>
    </component>
</template>

<script>

export default {
    name: 'List',
    props: {
        tag: {
            type: String,
            default: 'ul'
        },
        items: {
            type: Array,
            default: () => [],
        },
        inline: {
            type: Boolean,
            default: false,
        },
        border: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        className() {
            let classname = 'c-list'

            if(this.inline) {
                classname += '-inline'
            }

            return classname
        }
    },
};

</script>

<style lang="scss">

/*===================================
=            Column list            =
===================================*/

.c-list {
    // --list-cols: 1;
    // --list-cols-xs: 2;
    // --list-cols-sm: 2;
    // --list-cols-md: 3;
    // --list-cols-lg: 3;

    display: grid;
    grid-template-columns: repeat(var(--list-cols, 1), 1fr);
    grid-gap: var(--grid-gutter);
    list-style-type: none;

    @media #{md("xs")} {
        --list-cols: var(--list-cols-xs, 2);
    }

    @media #{md("sm")} {
        --list-cols: var(--list-cols-sm, var(--list-cols-xs, 2));
    }

    @media #{md("md")} {
        --list-cols: var(--list-cols-md, 3);
    }

    @media #{md("lg")} {
        --list-cols: var(--list-cols-lg, var(--list-cols-md, 3));
    }
}

.c-list__item {
    padding-top: 1em;
    padding-bottom: 1em;
    border-top: $border-secondary;
}



/*===================================
=            Inline list            =
===================================*/

.c-list-inline {
    display: flex;
    flex-wrap: wrap;
    margin: -.25em;

    > * {
        margin: .25em 1em .25em .25em;

    }
}



</style>
