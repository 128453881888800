<template>
    <div>
        <hero :title="page.title" :intro="page.intro" :image="page.image" display="image-left" :bg="true">
            <template #after-content>
                <div class="c-list-inline">
                    <btn label="Employés en action" modifier="underline" @click.native="scrollTo('#employes')" />
                    <btn label="Historique" modifier="underline" @click.native="scrollTo('#historique', 60)" />
                    <btn label="Prix et distinctions" modifier="underline" @click.native="scrollTo('#prix', 60)" />
                    <btn label="Équipe de direction" modifier="underline" @click.native="scrollTo('#equipe')" />
                </div>
            </template>
        </hero>

        <app-section v-if="page.about" :bg="true">
            <div class="o-about">
                <list :items="page.about.list" class="o-about__list">
                    <template #default="item">
                        <div class="t-content">
                            <h3 class="t-t4">{{ item.heading }}</h3>
                            <div v-if="item.body" class="t-cms" v-html="item.body"></div>
                            <ul v-if="item.list && item.list.length > 0">
                                <li v-for="(subitem, i) in item.list" :key="`subitem-${i}`" class="t-t5-bold">
                                    {{ subitem.item }}
                                </li>
                            </ul>
                        </div>
                    </template>
                </list>
                <figure class="o-about__fig">
                    <asset :asset="page.aboutImage" :radius="true" />
                </figure>

                <auto-slider
                    id="employes"
                    class="o-about__gallery"
                    :slides="[...page.about.gallery, ...page.about.gallery]"
                    title="Nos employés en action"
                    ref="autoSlider"
                >
                    <template #slide="slide">
                        <div class="o-about__gallery-cell">
                            <div class="o-about__gallery-content">
                                <h3 class="t-t5-bold">{{ slide.fullname }}</h3>
                                <h4 class="t-caps -xs">{{ slide.store }}</h4>
                            </div>
                            <asset :asset="slide.photo" :lazy="false" @loaded="$refs.autoSlider.resize()" />
                        </div>
                    </template>
                </auto-slider>

                <div id="historique" class="o-about__slider | t-content">
                    <icon icon="history" />
                    <anim-text text="Historique" tag="h3" class="t-t5-bold" />
                    <slider
                        :slides="page.about.history"
                        :translate="false"
                        direction="vertical"
                        :options="{ adaptiveHeight: true }"
                    >
                        <template #slide="slide">
                            <anim-text
                                class="t-huge"
                                :number="true"
                                :text="slide.year"
                                :reveal="false"
                                :visible="slide.isActive"
                                :options="{ outDelta: -1, outDuration: 0.5 }"
                            />
                            <div
                                v-html="slide.desc.replace(/\n/g, '<br />')"
                                class="o-about__slide | t-cms"
                                :class="{ '-visible': slide.isActive }"
                            />
                            <!-- <anim-text
                                :text="slide.desc.replace(/\n/g, '<br />')"
                                :lines="true"
                                :reveal="false"
                                :visible="slide.isActive"
                                :options="{ inDelay: 1, outDelta: -1, outDuration: .5 }"
                            /> -->
                        </template>
                    </slider>
                </div>
                <div id="prix" class="o-about__slider | t-content">
                    <icon icon="award" />
                    <anim-text text="Prix et distinctions" tag="h3" class="t-t5-bold" />
                    <slider
                        :slides="page.about.awards"
                        :translate="false"
                        direction="vertical"
                        :options="{ adaptiveHeight: true }"
                    >
                        <template #slide="slide">
                            <anim-text
                                class="t-huge"
                                :number="true"
                                :text="slide.year"
                                :reveal="false"
                                :visible="slide.isActive"
                                :options="{ outDelta: -1, outDuration: 0.5 }"
                            />
                            <div
                                v-html="slide.desc.replace(/\n/g, '<br />')"
                                class="o-about__slide | t-cms"
                                :class="{ '-visible': slide.isActive }"
                            />
                            <!-- <anim-text
                                :text="slide.desc.replace(/\n/g, '<br />')"
                                :lines="true"
                                :reveal="false"
                                :visible="slide.isActive"
                                :options="{ inDelay: 1, outDelta: -1, outDuration: .5 }"
                            /> -->
                        </template>
                    </slider>
                </div>
            </div>
        </app-section>

        <app-section v-if="page.director || page.team.length > 0" id="equipe">
            <grid-content :sticky="true">
                <template #left>
                    <anim-text :text="page.heading1" tag="h2" class="t-t4 | u-color-orange" />
                </template>
                <template #right>
                    <article class="o-director">
                        <asset
                            :asset="page.director.photo"
                            :radius="true"
                            :title="`Photo de ${page.director.fullname}, ${page.director.job}`"
                            class="o-director__photo"
                        />
                        <header class="o-director__heading | t-content">
                            <h3 class="t-t5-bold">
                                {{ page.director.fullname }}
                            </h3>
                            <span v-if="page.director.job" class="t-small">{{ page.director.job }}</span>
                            <p>
                                <a
                                    v-if="page.director.linkedIn"
                                    :href="page.director.linkedIn"
                                    :title="`Page LinkedIn de ${page.director.fullname}`"
                                    target="_blank"
                                    rel="noopener"
                                    class="o-director__social"
                                >
                                    <icon icon="linkedin" />
                                    <span class="t-small">LinkedIn</span>
                                </a>
                            </p>
                        </header>
                        <div class="o-director__content | t-content">
                            <div class="t-cms" v-html="page.director.body"></div>
                            <btn
                                v-if="page.directorFile"
                                tag="a"
                                :href="page.directorFile.url"
                                :title="page.directorFile.title"
                                label="Télécharger la biographie complète"
                                modifier="underline"
                            />
                        </div>
                    </article>

                    <list :items="page.team" :border="true">
                        <template #default="member">
                            <div class="t-content">
                                <asset
                                    :asset="member.photo"
                                    :radius="true"
                                    :title="`Photo de ${member.fullname}, ${member.job}`"
                                />
                                <h3 class="t-t5-bold">
                                    {{ member.fullname }}
                                </h3>
                                <span class="t-small">{{ member.job }}</span>
                                <p v-if="member.precision" class="t-small | u-color-primary">{{ member.precision }}</p>
                            </div>
                        </template>
                    </list>
                </template>
            </grid-content>
        </app-section>
    </div>
</template>

<script>
import Hero from "layout/Hero";
import AppSection from "layout/AppSection";
import GridContent from "layout/GridContent";

import Icon from "objects/Icon";
import Asset from "objects/Asset";
import AnimText from "objects/AnimText";

import Btn from "components/Btn";
import List from "components/List";
import Slider from "components/Slider";
import AutoSlider from "components/AutoSlider";

import { gsap, ScrollToPlugin } from "gsap/all";
gsap.registerPlugin(ScrollToPlugin);

export default {
    name: "About",
    components: {
        Hero,
        AppSection,
        GridContent,
        Icon,
        Asset,
        AnimText,
        Btn,
        List,
        Slider,
        AutoSlider
    },
    props: {
        page: Object
    },
    metaInfo() {
        return {
            title: this.page.seo ? this.page.seo.title : null
        };
    },
    methods: {
        scrollTo(target, offset) {
            gsap.to(window, {
                duration: 1,
                scrollTo: {
                    y: target,
                    offsetY: offset
                },
                ease: "expo.inOut"
            });
        }
    }
};
</script>

<style lang="scss">
.p-about {
    .l-hero {
        .c-list-inline {
            margin-top: 2em;

            .c-btn {
                --btn-border-color: #fff;
            }
        }
    }

    // [class*=-arrow-].o-icon svg {
    //     transform: translateY(-1px)
    // }
}

/*=============================
=            About            =
=============================*/

.o-about {
    display: grid;
    grid-gap: 4em 2em;
    margin-bottom: 2em;

    @media #{md("sm")} {
        grid-template-columns: repeat(2, (minmax(0, 1fr)));
    }

    @media #{md("md")} {
        grid-gap: 6em calc(1 / 12 * 100%);
    }
}

.o-about__slider {
    display: flex;
    flex-direction: column;

    .c-slider {
        @media #{md("sm")} {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
        }

        .o-about__slide {
            opacity: 0;
            transition: opacity ease 0.2s;
            transition-delay: 0s;

            &.-visible {
                opacity: 1;
                transition: opacity ease 0.2s;
                transition-delay: 0.2s;
            }
        }
    }
}

.o-about__list {
    --list-cols: 1;

    .c-list__item {
        padding-top: 2em;

        ul {
            @include reset-list;
            padding-top: 1em;

            li {
                margin-top: 0.75em;
            }
        }
    }
}

.o-about__fig {
    @media #{md("sm")} {
        right: calc(-1 * var(--grid-start-x));

        .o-asset {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
}

.o-about__gallery {
    //     --slide-width: auto;

    .c-auto-slider__slide {
        border-top-left-radius: $border-radius-md;
        border-top-right-radius: $border-radius-md;
        border-bottom-left-radius: $border-radius-md;
        overflow: hidden;

        //         &:not(:first-child) {
        //             margin-left: 2em;
        //         }

        &:nth-child(3n) {
            .o-asset__img {
                height: vh(50);

                html.is-mobile-safari & {
                    height: 30rem;
                }
            }
        }
    }

    .o-asset__img {
        width: auto;
        height: vh(40);

        html.is-mobile-safari & {
            height: 20rem;
        }
    }

    @media #{md("sm")} {
        grid-column-start: 1;
        grid-column-end: 3;
    }
}

.o-about__gallery-content {
    z-index: 1;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 1em;

    > *:not(:first-child) {
        margin-top: 0.3rem;
    }

    &:before {
        @include pseudo-el();
        position: absolute;
        top: 0;
        left: 0;
        @include linear-gradient(to top, $color-dark, rgba($color-dark, 0));
        opacity: 0.6;
    }
}

/*================================
=            Director            =
================================*/

.o-director {
    display: grid;
    grid-gap: 1em var(--grid-gutter);
    margin-bottom: var(--grid-gutter);

    @media #{md("md")} {
        grid-template-areas:
            "photo   photo   photo"
            "heading content content";
        grid-template-columns: 1fr 2fr;
        grid-row-gap: 2em;
        margin-bottom: calc(2 * var(--grid-gutter));
    }
}

.o-director__photo {
    @media #{md("md")} {
        grid-area: photo;
    }
}

.o-director__heading {
    @media #{md("md")} {
        grid-area: heading;
    }
}

.o-director__social {
    .o-icon {
        margin-right: 0.5em;
    }
}

.o-director__content {
    @media #{md("md")} {
        grid-area: content;
    }
}
</style>
