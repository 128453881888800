/*
 * Articles
 */

import Crud from '../crud';
const crud = new Crud('article');

// State
const state = {
    ...crud.state
}

// Getters
const getters = {
    ...crud.getters
}

// Actions
const actions = {
    ...crud.actions
}

// Mutations
const mutations = {
    ...crud.mutations
}

// Export module
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
}
