import Vue from "vue";
import VueRouter from "vue-router";
import store from "src/store";
import { STATIC_ROUTES } from "src/constants";

// Views
import * as Components from "./templates/views";

// Use plugin
Vue.use(VueRouter);

// Dynamic Routes from Craft CMS
const dynamicRoutes = window.__initialData__.routes.map(r => ({ ...r, component: Components[r.component] }));

// Static routes
const staticRoutes = [
    {
        name: "Partnership",
        path: STATIC_ROUTES.PARTNERSHIP,
        component: Components.Partnership,
        meta: {
            modal: true
        }
    },
    {
        name: "SimpleJob",
        path: STATIC_ROUTES.SIMPLEJOB,
        component: Components.SimpleJob,
        meta: {
            modal: true
        }
    },
    {
        name: "Error404",
        path: "*",
        redirect: "/404"
    }
];

// Merge dynamic and static routes
const routes = [...dynamicRoutes, ...staticRoutes];

// Create the router with the routes
const router = new VueRouter({
    mode: "history",
    base: "/",
    routes,
    linkExactActiveClass: "is-active"
});

// Before each route load
router.beforeEach((to, from, next) => {
    // Set previous route if exists
    if (from.name !== null) {
        store.dispatch("global/updatePreviousRoute", from);
    }

    // Close modal if open
    if (store.getters["modal/hasModal"]) {
        store.dispatch("modal/close");
    }

    // Load page
    store
        .dispatch("global/loadContent", to)
        .then(next)
        .catch(e => {
            console.error(e.message); // eslint-disable-line
        });
});

// Link resolver
const linkResolver = (section, slug = false) => {
    const route = routes.find(r => r.meta && r.meta.section === section);
    let path = route ? route.path : "/";

    if (route && slug) {
        path = path.replace(":slug", slug);
    }

    return path;
};

// Export
export default router;
export { router, routes, linkResolver };
