<template>
    <div v-if="totalSections > 0" class="c-section-list" :class="{ 'has-nav': hasFilters }">
        <nav v-if="hasFilters" class="c-section-list__nav" :class="{ 'has-scroll': hasOverflow }">
            <div class="c-section-list__filters" ref="filters">
                <button
                    v-for="(section, i) in sections"
                    :key="`section-list-filter-${section.id}`"
                    class="c-section-list__filter"
                    ref="filter"
                    :class="{ 'is-active': activeIndex == i }"
                    @click="scrollTo(i)"
                >
                    {{ section.title }}
                </button>
            </div>
        </nav>
        <app-section
            v-for="(section, i) in sections"
            :key="`section-list-section-${section.id}`"
            :data-index="i"
            :id="section.slug || section.id"
            class="c-section-list__item"
            ref="section"
        >
            <anim-text :text="section.title" tag="h2" class="t-t3" />
            <card-list v-if="isGrouped" :cards="section.cards" :group-by="isGrouped" :order-by="isOrdered" />
            <card-grid v-else :cards="section.cards" />
        </app-section>
    </div>
</template>

<script>
import AppSection from "layout/AppSection";
import AnimText from "objects/AnimText";
import CardGrid from "components/CardGrid";
import CardList from "components/CardList";

import { gsap, ScrollToPlugin } from "gsap/all";
gsap.registerPlugin(ScrollToPlugin);

export default {
    name: "SectionList",
    components: {
        AppSection,
        AnimText,
        CardGrid,
        CardList,
    },
    props: {
        isGrouped: {
            type: Object,
            default: null,
        },
        isOrdered: {
            type: String,
            default: null,
        },
        sections: {
            type: Array,
            default: () => [],
        },
    },
    data: () => ({
        activeIndex: 0,
        isActive: true,
        sectionElements: null,
        hasOverflow: false,
    }),
    mounted() {
        if (!this.hasFilters) {
            return;
        }

        // Observer
        const observerOptions = {
            root: null,
            threshold: 0.5,
        };

        // Create observer
        this.observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && this.isActive) {
                    this.activeIndex = entry.target.dataset.index;
                }
            });
        }, observerOptions);

        // Get all section html elements
        this.sectionElements = this.$refs.section.map((section) => section.$el);

        // Add to observer
        this.sectionElements.forEach((section) => this.observer.observe(section));

        this.filters = this.$refs.filters;
        window.addEventListener("resizeEnd", this.setOverflow);

        this.setOverflow();
    },
    computed: {
        hasFilters() {
            return this.totalSections > 1;
        },
        totalSections() {
            return this.sections.length;
        },
    },
    methods: {
        scrollTo(index) {
            const target = this.sectionElements[index];
            this.activeIndex = index;
            gsap.to(window, {
                duration: 1,
                scrollTo: {
                    y: target,
                    // Header height
                    offsetY: 60,
                },
                ease: "expo.inOut",
                onStart: () => (this.isActive = false),
                onComplete: () => {
                    this.isActive = true;
                },
            });
        },
        setOverflow() {
            this.filtersWidth = this.filters.offsetWidth;
            this.filtersScrollWidth = this.filters.scrollWidth;

            this.hasOverflow = !(this.filtersWidth === this.filtersScrollWidth);
        },
    },
    watch: {
        activeIndex(index) {
            if (this.hasOverflow) {
                const rect = this.$refs.filter[index].getBoundingClientRect();

                const x = index > 0 ? this.filters.scrollLeft + rect.left - (this.filtersWidth - rect.width) / 2 : 0;

                gsap.to(this.filters, {
                    duration: 0.4,
                    scrollTo: {
                        x,
                    },
                });
            }
        },
    },
    beforeDestroy() {
        this.observer.disconnect();
    },
};
</script>

<style lang="scss">
.c-section-list {
    &.has-nav {
        padding-bottom: 4rem;
    }

    h2 {
        z-index: 2;
    }
}

.c-section-list__nav {
    --section-list-nav-height: 4.5em;

    position: -webkit-sticky;
    position: sticky;
    top: calc(#{vh(100)} - var(--section-list-nav-height) - 0.5em);
    left: 0;
    z-index: 5;
    width: 100%;
    margin-bottom: calc(-1 * var(--section-list-nav-height));

    display: flex;
    justify-content: center;

    transition: top ease 0.15s; // Fix iOS glitch

    &.has-scroll {
        top: calc(#{vh(100)} - var(--section-list-nav-height));

        .c-section-list__filters {
            position: static;
        }
    }
}

.c-section-list__filters {
    display: flex;
    align-items: center;
    white-space: nowrap;
    height: var(--section-list-nav-height);
    overflow: auto;

    // Hide scrollbar
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }

    @include blurred-bg($radius: $border-radius-sm);

    @media #{md("md")} {
        align-items: center;
        padding-right: 1em;
        padding-left: 1em;
    }
}

.c-section-list__filter {
    z-index: 0;
    display: block;
    margin-right: 0.5em;
    margin-left: 0.5em;
    cursor: pointer;
    transition: all 0.2s ease-out;

    &:before {
        @include pseudo-el($bg: $color-dark);
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: $border-radius-sm;
        opacity: 0;
        transition: opacity 0.2s ease-out;
    }

    &:hover {
        opacity: 0.5;
    }

    &.is-active {
        color: $color-light;
        pointer-events: none;

        &:before {
            opacity: 1;
        }
    }

    @media #{md("md", "max")} {
        padding: 1em 1.25em;
    }

    @media #{md("md")} {
        padding: 0.75em;
    }
}
</style>
