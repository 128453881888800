<template>
    <div
        class="c-auto-slider"
        v-reveal="revealHandle"
    >
        <header
            v-if="title"
            class="c-auto-slider__header"
        >
            <anim-text
                :text="title"
                class="c-auto-slider__title | t-t5-bold"
            />
        </header>
        <flickity
            ref="flkty"
            class="c-auto-slider__inner"
            :options="opts"
            @mouseenter.native="pause"
            @focusin.native="pause"
            @mouseleave.native="play"
            @focusout.native="play"
        >
            <div
                v-for="(slide, i) in slides"
                :key="`slider-${_uid}-slide-${i}`"
                class="c-auto-slider__slide"
            >
                <slot name="slide" v-bind="slide">
                    {{ slide }}
                </slot>
            </div>
        </flickity>
    </div>
</template>

<script>

import AnimText from 'objects/AnimText'
import Flickity from 'vue-flickity'
import Variables from 'src/mixins/variables';

export default {
    name: 'AutoSlider',
    components: {
        AnimText,
        Flickity,
    },
    mixins: [ Variables ],
    props: {
        title: {
            type: String,
            default: null,
        },
        slides: {
            type: Array,
            default: () => [],
        },
    },
    data: () => ({
        isRevealed: false,
        isActive: false,
        opts: {
            autoPlay: false,
            wrapAround: true,
            prevNextButtons: false,
            pageDots: false,
            cellAlign: 'left',
            draggable: true,
            freeScroll: true
        },
        tickerSpeed: 0,
        isPaused: false
    }),
    mounted() {
        this.tickerSpeed = this.W.w > 500 ? 1 : 0.25
        this.flkty = this.$refs.flkty.$flickity
        this.flkty.x = 0

        this.flkty.on('dragStart', this.onDragStart = () => this.isPaused = true );
        //window.addEventListener("resize", this.resize)

    },
    computed: {
        totalSlides() {
            return this.slides.length
        },
    },
    methods: {

        revealHandle(state) {

            this.isActive = state.isActive

            if(this.isActive && !this.isRevealed) {
                this.update()
                this.isRevealed = true
            }
        },
        update() {

            const flickity = this.flkty

            if (this.isPaused)
                return;

            if (flickity.slides) {
                flickity.x = (flickity.x - this.tickerSpeed) % flickity.slideableWidth;
                flickity.selectedIndex = flickity.dragEndRestingSelect();
                flickity.updateSelectedSlide();
                flickity.settle(flickity.x);
            }
            window.requestAnimationFrame(this.update);
        },
        pause() {
            this.isPaused = true;
        },
        play() {
            if (this.isPaused) {
                this.isPaused = false;
                window.requestAnimationFrame(this.update)
            }
        },
        resize() {
            this.update()
            this.$refs.flkty.resize()
        },
    },
    beforeDestroy() {
        this.tickerSpeed = 0
        this.flkty.x = 0
        this.flkty.off('dragStart', this.onDragStart);
        //window.removeEventListener('resize', this.resize)
    }
};

</script>

<style lang="scss">


.c-auto-slider {

    .flickity-viewport {
        width: 100vw;
        margin-left: calc(-1 * var(--grid-start-x));
    }

    .flickity-slider {
        display: flex;
        align-items: center;
    }
}

.c-auto-slider__header {
    margin-bottom: 2em;

    &:before {
        @include pseudo-el($height: 1px, $bg: $color-secondary);
        position: absolute;
        bottom: 0;
        left: 0;
    }
}

.c-auto-slider__title {
    display: inline-block;
    padding-top: 1.5em;
    padding-bottom: 1.5em;

    &:after {
        @include pseudo-el($height: 3px, $bg: $color-light);
        position: absolute;
        bottom: -1px;
        left: 0;
    }
}

.c-auto-slider__inner {
    max-width: calc(100vw - (2 * var(--grid-start-x)))
}

.c-auto-slider__slide {
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 2em;
}


</style>
