<template>
    <span
        v-if="totalWords > 0"
        class="o-rollup"
        v-reveal="revealHandle"
    >
        <anim-text
            v-for="(word, i) in words"
            :key="`rollup-${_uid}-${i}`"
            :reveal="false"
            class="o-rollup__word"
            :text="word.item"
            ref="w"
            :visible="i === activeIndex"
            :options="{ inDelay: .2, outDuration: .4, outDelta: -1 }"
        />
    </span>
</template>

<script>

import AnimText from 'objects/AnimText'

import { gsap } from 'gsap/all'

export default {
    name: 'Rollup',
    components: {
        AnimText,
    },
    props: {
        words: {
            type: Array,
            default: () => [],
        },
    },
    data: () => ({
        activeIndex: 0,
        isPlaying: false,
    }),
    mounted() {
        this.animate()
    },
    computed: {
        totalWords() {
            return this.words.length
        },
    },
    methods: {
        animate() {
            const proxy = {
                index: 0
            }

            const wordsIndex = this.totalWords - 1
            const itemDuration = 2

            this.tl = gsap.timeline({ paused: true, repeat: -1, repeatDelay: itemDuration })
                .to(proxy, wordsIndex * itemDuration, {
                index: wordsIndex,
                ease: 'linear',
                onUpdate: () => {
                    this.activeIndex = Math.floor(proxy.index)
                }
            });
        },
        revealHandle(state) {
            if(state.isActive && !this.isPlaying) {
                this.isPlaying = true
                this.tl.play()
            } else if(!state.isActive) {
                this.isPlaying = false
                this.tl.pause()
            }
        },
    },
};

</script>

<style lang="scss">

.o-rollup {
    display: inline-block;
}

.o-rollup__word {

    &:not(:first-child) {
        position: absolute;
        top: 0;
        left: 0;
    }
}

</style>
