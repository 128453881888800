<template>
    <tag
        v-if="award && award.display"
        :is="tag"
        class="c-award"
        :class="{'-boxed': boxed}"
    >
        <span class="c-award__inner">
            <icon
                v-if="award.logo"
                icon="logo-smg"
                class="c-award__logo"
            />
            <span
                class="c-award__content | t-caps -xs"
                v-html="award.content"
            ></span>
        </span>
    </tag>
</template>

<script>

import Icon from 'objects/Icon';

import { mapGetters } from 'vuex'

export default {
    name: 'Award',
    components: {
        Icon
    },
    props: {
        tag: {
            type: String,
            default: 'span',
        },
        boxed: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        ...mapGetters({
            award: 'global/getAward',
        }),
    }
}
</script>

<style lang="scss">

.c-award {
    display: block;

    @media #{md("xs", "max")} {
        width: calc(100% - var(--grid-start-x) * 3);
    }

    &.-boxed {
        --award-padding-y: 1.25em;
        --award-padding-x: 1.5em;
        --award-color: #{$color-light};
        --award-bg-color: #{$color-dark};

        .c-award__logo {

            & + .c-award__content {
                margin-left: 2em;
                padding-right: 0;
            }
        }
    }
}

.c-award__inner {
    display: inline-flex;
    align-items: center;
    padding: var(--award-padding-y, 0) var(--award-padding-x, 0);
    color: var(--award-color, inherit);
    background-color: var(--award-bg-color, none);
    border-radius: $border-radius-sm;
}

.c-award__logo {
    flex-shrink: 0;

    & + .c-award__content {
        margin-left: 1.25em;
        padding-right: 2em;
    }
}

.c-award__content {
    font-weight: 100;
}

</style>
