/*
** Loader
*/

// State
const state = {
    data: false,
    component: false,
}

// Getters
const getters = {
    hasModal: state => state.component !== false,
}

// Actions
const actions = {
    open(store, { component, data }) {
        store.commit('openModal', { component, data })
    },
    close(store) {
        store.commit('closeModal', false)
    },
}

// Mutations
const mutations = {
    openModal(state, { component, data }) {
        state.component = component
        state.data = data
    },
    closeModal(state) {
        state.data = false
        state.component = false
    },
}

// Export module
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
}
