import Vue from 'vue'
import { linkResolver } from 'src/router'


// Prefix url for translation
Vue.filter('resolver', (section, slug=false) => {
    return linkResolver(section, slug)
})


// Prefix url for translation
Vue.filter('tel', number => {

    // Remove all non numeric characters
    number = number.replace(/\D/g,'');

    number = `tel:+${number}`
    return number
})

Vue.filter('truncate', function (text, length, clamp) {

    clamp = clamp || '...';
    length = length || 144;
    const node = document.createElement('div');
    node.innerHTML = text;
    const content = node.textContent;
    return content.length > length ? content.slice(0, length) + clamp : content;

})

// Capitalize string
Vue.filter('capitalize', str => str.charAt(0).toUpperCase() + str.slice(1))
