<template>
    <article :class="className">
        <div
            class="c-card-job__inner"
            @click="handleMouseClick()"
            @mouseenter="handleMouseEnter"
            @mouseleave="handleMouseLeave"
        >
            <!-- JOB -->
            <template v-if="isJob">
                <div class="c-card-job__asset">
                    <asset :asset="data.cardImage" :radius="true" :cover="true" />
                </div>

                <div class="c-card-job__content">
                    <div class="-top">
                        <banners-list v-if="data.store" :items="data.store.banners" />
                        <h3 class="c-card-job__title | t-t5">
                            {{ data.title }}
                        </h3>
                        <h4 v-if="data.department" class="c-card-job__department | t-caps">
                            {{ data.department.title }}
                        </h4>
                    </div>
                    <div class="-bottom" v-if="data.employmentType.length">
                        <tag v-for="(type, i) in data.employmentType" :label="type" :key="`employmentType-${i}`" />
                    </div>
                </div>

                <div class="c-card-job__button">
                    <btn
                        tag="router-link"
                        :href="link"
                        title="Postuler"
                        label="Voir"
                        modifier="lighter"
                        icon-after="arrow-right"
                        ref="btn"
                    />
                </div>
            </template>
        </div>
    </article>
</template>

<script>
import Asset from "objects/Asset";
import BannersList from "objects/BannersList";
import Tag from "objects/Tag";
import Btn from "components/Btn";

import { linkResolver } from "src/router";
import { SECTION_HANDLES } from "src/constants";

export default {
    name: "Card",
    components: {
        Asset,
        BannersList,
        Btn,
        Tag,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        SECTION_HANDLES,
    }),
    computed: {
        className() {
            let classname = "c-card-job";

            return classname;
        },
        type() {
            return this.data.sectionHandle;
        },
        isJob() {
            return this.type === SECTION_HANDLES.JOB;
        },

        link() {
            let path = false;

            if (this.isJob) {
                path = `${linkResolver(SECTION_HANDLES.JOBS)}/${this.data.slug}`;
            }

            return path;
        },
    },
    methods: {
        handleMouseEnter(event) {
            const parent = event.target.parentElement;
            parent.classList.add("is-hover");
        },
        handleMouseLeave(event) {
            const parent = event.target.parentElement;
            parent.classList.remove("is-hover");
        },
        handleMouseClick() {
            this.$refs.btn.$el.click();
        },
    },
};
</script>

<style lang="scss">
.c-card-job {
    --card-padding: 1.25rem;

    &__inner {
        display: grid;
        grid-template-columns: 8rem auto 4rem;
        gap: var(--card-padding);

        &:hover {
            cursor: pointer;
        }

        @media #{md("md", "max")} {
            grid-template-columns: 8rem auto;
        }
    }

    &__asset {
        aspect-ratio: 4/5;
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: var(--card-padding);
        padding-top: 0.4rem;

        .-bottom {
            .o-tag {
                margin-right: 0.4rem;
                margin-bottom: 0.4rem;
            }
        }
    }

    .c-card-job__title {
        margin-top: var(--card-padding);
        margin-bottom: 0.2rem;
    }
    .c-card-job__department {
        opacity: 0.5;
    }

    .c-card-job__button {
        display: flex;
        justify-content: center;
        flex-direction: column;

        @media #{md("md", "max")} {
            grid-column: 2/3;
        }

        .c-btn {
            --btn-padding-y: 0.75em;
            --btn-padding-x: 1em;

            @media #{md("md")} {
                --btn-padding-y: 1.25em;
                --btn-padding-x: 1.25em;
            }

            &__label {
                @media #{md("md")} {
                    display: none;
                }
            }
        }
    }

    // Transitions
    .c-card-job__asset {
        transform: translateZ(0);

        .o-asset {
            overflow: hidden;

            .o-asset__img {
                transition: transform 0.5s $out-quad;
            }
        }
    }

    &.is-hover {
        .c-card-job__asset {
            .o-asset__img {
                transform: scale(1.05);
                transition: transform 1.75s $out-quad;
            }
        }
    }
}
</style>
