<template>
    <div>
        <hero :title="page.title" :intro="page.intro" :image="page.image" display="image-top">
            <template #after-image>
                <deco class="p-jobs__deco" field="hockey" :ball="true" />
            </template>

            <template #after-content>
                <btn
                    tag="router-link"
                    :href="STATIC_ROUTES.SIMPLEJOB"
                    label="Soumettre votre candidature"
                    icon-after="arrow-right"
                    modifier="border"
                />
            </template>
        </hero>

        <div v-if="sections.length === 0" class="grid">
            <anim-text text="Il n'y a aucune offre d'emploi pour le moment." tag="h2" class="p-jobs__empty | t-t4" />
        </div>

        <section-list v-else :sections="sections" :is-grouped="{ prop: 'store', key: 'slug' }" is-ordered="stores" />
    </div>
</template>

<script>
import Hero from "layout/Hero";
import Icon from "objects/Icon";
import Deco from "objects/Deco";
import AnimText from "objects/AnimText";
import Btn from "components/Btn";
import SectionList from "components/SectionList";

import { SECTION_HANDLES, MODAL_COMPONENTS, STATIC_ROUTES } from "src/constants";

import { mapState } from "vuex";

export default {
    name: "Jobs",
    components: {
        Hero,
        Icon,
        Deco,
        AnimText,
        Btn,
        SectionList,
    },
    props: {
        page: Object,
    },
    data: () => ({
        STATIC_ROUTES,
    }),
    metaInfo() {
        return {
            title: this.page.seo ? this.page.seo.title : null,
        };
    },
    created() {
        // Load job and open modal
        if (this.isJob) {
            // Load store data
            this.$store.dispatch("jobs/loadSingle", this.$route.params.slug).then((job) => {
                // Use store cover as fallback
                if (!job.cover) job.cover = job.store.cover;

                this.$store.dispatch("modal/open", { component: MODAL_COMPONENTS.JOB, data: job });
            });
        }
    },
    computed: {
        ...mapState({
            jobs: (state) => state.jobs.elements,
            regions: (state) => state.global.regions,
        }),
        isJob() {
            return this.$route.meta.section === SECTION_HANDLES.JOB;
        },

        sections() {
            const sections = [];

            this.regions.forEach((region) => {
                const jobsInRegion = this.jobs.filter((job) => job.store && job.store.regionID == region.id);

                if (jobsInRegion.length > 0) {
                    sections.push({
                        ...region,
                        cards: jobsInRegion,
                    });
                }
            });

            return sections;
        },
    },
};
</script>

<style lang="scss">
.p-jobs {
    .l-hero__col.-content {
        .t-content {
            margin-bottom: vh(5);

            html.is-mobile-safari & {
                margin-bottom: 2.25rem;
            }
        }
    }
}

.p-jobs__deco {
    --deco-size: 30vw;

    left: 1em;
    bottom: calc(-1 * var(--grid-start-x));

    html.is-mobile-safari & {
        bottom: -0.75rem;
    }

    @media #{md("lg")} {
        --deco-size: 26em;
        bottom: vh(-10);
    }
}

.p-jobs__empty {
    max-width: 32rem;
    margin-right: auto;
    margin-left: auto;
    padding-top: vh(10);
    padding-bottom: vh(15);
    text-align: center;

    html.is-mobile-safari & {
        padding-top: 4.5rem;
        padding-bottom: 5rem;
    }
}
</style>
