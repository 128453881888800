<template>
    <div>
        <hero :title="page.title" :intro="page.intro" :image="page.image" display="image-top" :bg="true" />

        <app-section v-if="page.body">
            <div v-html="page.body" class="t-cms" />
        </app-section>
    </div>
</template>

<script>
import Hero from "layout/Hero";
import AppSection from "layout/AppSection";
import GridContent from "layout/GridContent";

export default {
    name: "Pages",
    components: {
        Hero,
        AppSection,
        GridContent
    },
    props: {
        page: Object
    },
    metaInfo() {
        return {
            title: this.page.seo ? this.page.seo.title : null
        };
    }
};
</script>

<style lang="scss">
.p-pages {}
</style>
