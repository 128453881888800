/*
 * Craft GraphQL Queries
 */

import snippets from "./snippets";
import { getAsset } from "./assets";

/**********************************
 *
 *
 *  GENERAL
 *
 *
 **********************************/

// Main query
export const general = `{
    nav: globalSet(handle: "nav") {
        ... on nav_GlobalSet {
            navNodes {
                ...on navNodes_BlockType {
                    entry {
                        ${snippets.defaultData}
                        title
                        sectionHandle
                    }
                    label
                }
            }
        }
    }
    forms: globalSet(handle: "formConfirmation") {
        ... on formConfirmation_GlobalSet {

            # Job
            jobRecipient: email
            jobConfirmation: body2

            # Sponsorship
            sponsorShipConfirmation: body4
            sponsorShipRecipient: email2

            # Contact
            contactConfirmation: body6
            contactRecipient: email3
        }
    }
    settings: globalSet(handle: "settings") {
        ... on settings_GlobalSet {
            ${snippets.address}
            phone
            facebook
            linkedin
            privilegeZone: body1
            displayAward: trueOrFalse
            awardLogo: ${getAsset("logo")}
            awardText: heading1
        }
    }
    regions: categories(group: "regions") {
        title
        slug
        id
        order: lft
        ...on regions_Category {
            ${getAsset("image")}
        }
    }
    partnerCategories: categories(group: "partnerCategories") {
        title
        slug
        id
        ...on partnerCategories_Category {
            subtitle: heading1
            body
            ${getAsset("image", "landscape.2:1")}
        }
    }
    # banners: ${snippets.banners}
}`;

/**********************************
 *
 *
 *  CONTENT/PAGE
 *
 *
 **********************************/

// Homepage
export const home = `{
    entry(section: "home") {
        ${snippets.entry}

        ...on home_home_Entry {
            ${snippets.hero("header.home")}
            rollup {
                ...on rollup_TableRow {
                    item
                }
            }

            # Banner
            bannersTitle: heading1
            bannersBody: body1

            # About
            aboutTitle: heading2
            aboutBody: body2
            stats {
                ...on stats_TableRow {
                    number
                    label
                }
            }
            aboutImage: ${getAsset("image2", "landscape.3:2")}

            # Implications
            implicationsTitle: heading3
            ${snippets.seo}
        }
    }
    implicationImage: entry(section: "implications") {
        ...on implications_implications_Entry {
            ${getAsset("image", "landscape.2:1")}
        }
    }
    implicationCount: entryCount(section: "implication")
    partners: entries(section: "partner") {
        ...on partner_partner_Entry {
            type: selectCategoryPartner {
                id
            }
        }
    }
    articles: entries(section: "article", limit: 4) {
        ${snippets.articlePreload}
    }
}`;

// About
export const about = `{
    entry(section: "about") {
        ${snippets.entry}

        ...on about_about_Entry {
            ${snippets.hero("header.side")}
            ${snippets.seo}

            # Content
            aboutImage: ${getAsset("image2")}
            about {
                ...on about_BlockType {
                    list {
                        ...on list_content_BlockType {
                            heading
                            body
                        }
                        ...on list_list_BlockType {
                            heading
                            list {
                                ...on list_TableRow {
                                    item
                                }
                            }
                        }
                    }
                    gallery {
                        ...on gallery_photo_BlockType {
                            fullname
                            store {
                                title
                            }
                            ${getAsset("photo")}
                        }
                    }
                    history {
                        ...on history_TableRow {
                            year
                            desc
                        }
                    }
                    awards {
                        ...on awards_TableRow {
                            year
                            desc
                        }
                    }
                }
            }

            heading1

            # Director
            director {
                ...on director_BlockType {
                    fullname
                    job
                    linkedIn
                    body
                    ${getAsset("photo")}
                    file {
                        url
                        title
                    }
                }
            }

            # Team
            team {
                ...on team_member_BlockType {
                    fullname
                    job
                    precision
                    ${getAsset("photo", "card.default")}
                }
            }
        }
    }
}`;

// Implications
export const implications = `{
    entry(section: "implications") {
        ${snippets.entry}

        ...on implications_implications_Entry {
            ${snippets.hero("header.side")}
            ${snippets.seo}
        }
    }
    implications: entries(section: "implication") {
        title
        ...on implication_implication_Entry {
            ${getAsset("logo")}
            body
        }
    }
    partners: entries(section: "partner", orderBy: "title asc") {
        title
        ...on partner_partner_Entry {
            ${getAsset("logo")}
            website: outerlink
            type: selectCategoryPartner {
                id
            }
            store: selectEntryStore {
                title
            }
        }
    }
}`;

// Store
export const pages = slug => `{
    entry(section: "pages", slug: "${slug}") {
        ${snippets.pages}
    }
}`;

// Contact
export const contact = `{
    entry(section: "contact") {
        ${snippets.entry}
        title

        ...on contact_contact_Entry {
            ${snippets.hero()}
            left: body
            right: wysiwyg
            openingHours {
                day
                hours
            }
            ${snippets.seo}
        }
    }
}`;

/**********************************
 *
 *
 *  STORES
 *
 *
 **********************************/

// Stores
export const stores = `{
    entry(section: "stores", orderBy: "lft asc") {
        ${snippets.entry}

        ...on stores_stores_Entry {
            ${snippets.hero("header.side")}
            ${snippets.seo}
        }
    }
}`;

export const storePreloadAll = `{
    entries(section: "store", orderBy: "lft asc") {
        ${snippets.storePreload}
    }
}`;

// export const storesPartial = `{
//     entries(section: "store") {
//         ${snippets.storePartial}
//     }
// }`

// // Store partials
// export const storePartial = id => `{
//     entry(section: "store", id: "${id}" {
//         ${snippets.storePartial}
//     }
// }`

// Store
export const store = slug => `{
    entry(section: "store", slug: "${slug}") {
        ${snippets.store}
    }
}`;

/**********************************
 *
 *
 *  ARTICLES
 *
 *
 **********************************/

// Articles
export const articles = `{
    entry(section: "articles") {
        ${snippets.entry}

        ...on articles_articles_Entry {
            ${snippets.hero()}
            ${snippets.seo}
        }
    }
}`;

// Article preload
export const articlePreloadAll = `{
    entries(section: "article") {
        ${snippets.articlePreload}
    }
}`;

// Article
export const article = slug => `{
    entry(section: "article", slug: "${slug}") {
        ${snippets.article}
    }
}`;

/**********************************
 *
 *
 *  JOBS
 *
 *
 **********************************/

// Jobs
export const jobs = `{
    entry(section: "jobs") {
        ${snippets.entry}

        ...on jobs_jobs_Entry {
            ${snippets.hero()}
            ${snippets.seo}
        }
    }
}`;

// Job
export const jobPreloadAll = `{
    entries(section: "job") {
        ${snippets.jobPreload}
    }
}`;

export const job = slug => `{
    entry(section: "job", slug: "${slug}") {
        ${snippets.job}
    }
}`;

//
export default {
    general,
    home,
    pages,
    about,
    implications,
    contact,

    stores,
    storePreloadAll,
    store,

    articles,
    articlePreloadAll,
    article,

    jobs,
    jobPreloadAll,
    job
};
