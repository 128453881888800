<template>
    <component
        :is="tag"
        :class="className"
    >
        <div class="l-grid-content__col -left">
            <div
                ref="left"
                class="l-grid-content__inner"
            >
                <slot name="left" />
            </div>
        </div>
        <div class="l-grid-content__col -right">
            <slot name="right" />
        </div>
    </component>
</template>

<script>


export default {
    name: 'GridContent',
    props: {
        tag: {
            type: String,
            default: 'div'
        },
        sticky: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        className() {
            let classname = 'l-grid-content'

            if(this.sticky) {
                classname += ' -sticky'
            }

            return classname
        }
    },
};

</script>

<style lang="scss">

.l-grid-content {
    // --grid-col-left: 1fr;
    // --grid-col-right: 2fr;

    display: grid;
    grid-gap: var(--grid-gutter);

    &.-sticky {

        .l-grid-content__inner {
            position: -webkit-sticky;
            position: sticky;
            top: var(--header-height);
        }
    }

    @media #{md("sm")} {
        grid-template-columns: var(--grid-col-left, 1fr) var(--grid-col-right, 2fr);
    }
}

.l-grid-content__col {

    &.-left {}

    &.-right {}
}

</style>
