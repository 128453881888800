<template>
    <div></div>
</template>

<script>

import { MODAL_COMPONENTS } from 'src/constants'

export default {
    name: 'SimpleJob',
    props: {
        page: Object,
    },
    created() {
        this.$store.dispatch('modal/open', { component: MODAL_COMPONENTS.SIMPLEJOB })
    },
};

</script>

<style lang="scss">
</style>
