<template>
    <button class="o-btn-close">
        <span class="o-btn-close__inner">
            <span class="o-btn-close__label | t-small">
                <slot>
                    <span class="o-btn-close__text">Fermer</span>
                </slot>
            </span>
            <span class="o-btn-close__icon">
                <span class="o-btn-close__dot -tl"></span>
                <span class="o-btn-close__dot -tr"></span>
                <span class="o-btn-close__dot -bl"></span>
                <span class="o-btn-close__dot -br"></span>
                <span class="o-btn-close__dot -c"></span>
            </span>
        </span>
    </button>
</template>

<script>

export default {
    name: 'BtnClose',
};

</script>

<style lang="scss">

.o-btn-close {
    cursor: pointer;

    &:hover {

        .o-btn-close__icon {
            color: $color-primary;
        }
    }
}

.o-btn-close__inner {
    display: inline-flex;
    align-items: center;
    padding: 1em 1.5em;
}

.o-btn-close__icon {
    display: block;
    width: 14px;
    height: 14px;
    transition: transform .4s $out-quad, color .3s ease-out;

    .nav-is-open & {
        transform: rotate(180deg);
    }
}

.o-btn-close__dot {
    position: absolute;
    display: block;
    width: 4px;
    height: 4px;
    background-color: currentColor;
    border-radius: 50%;
    transition: transform .4s $out-quad;

    &.-tl {
        top: 0;
        left: 0;
    }

    &.-tr {
        top: 0;
        right: 0;
    }

    &.-bl {
        bottom: 0;
        left: 0;
    }

    &.-br {
        right: 0;
        bottom: 0;
    }

    &.-c {
        top: calc(50% - 4px/2);
        left: calc(50% - 4px/2);
    }
}

.o-btn-close__label {
    margin-right: 1em;
}

.o-btn-close__text {
    display: block;
}

</style>
