<template>
    <tag :is="tag" :class="className">
        <span class="o-tag__inner">
            <span class="o-tag__label | t-caps -xs">
                <slot>{{ label }}</slot>
            </span>
        </span>
    </tag>
</template>

<script>
import Icon from "objects/Icon";

export default {
    name: "Tag",
    components: {
        Icon,
    },
    props: {
        tag: {
            type: String,
            default: "span",
        },
        label: {
            type: String,
            required: true,
        },
        modifier: {
            type: String,
            default: "blue-light",
        },
    },
    computed: {
        className() {
            let classname = "o-tag";

            if (this.modifier) {
                classname += ` -${this.modifier}`;
            }

            return classname;
        },
    },
};
</script>

<style lang="scss">
.o-tag {
    --tag-padding-y: 0.35em;
    --tag-padding-x: 0.85em;
    --tag-text-color: inherit;
    --tag-bg-color: transparent;

    display: inline-block;

    &__inner {
        display: inline-block;
        color: var(--tag-text-color);
        background-color: var(--tag-bg-color);
        padding: var(--tag-padding-y) var(--tag-padding-x);
        border-radius: $border-radius-sm;
    }

    &.-blue-light {
        --tag-text-color: #{$color-dark};
        --tag-bg-color: #{$color-blue-light};
    }
}
</style>
