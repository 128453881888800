<template>
    <div
        class="o-banners-list"
        :class="{ 'has-logo': displayLogo }"
    >
        <template
            v-for="(banner, i) in items"
        >
            <img
                :key="`banners-list-${_uid}-${i}`"
                v-if="displayLogo"
                :src="banner.logo.url"
                alt=""
            />
            <span
                v-else
                :key="`banners-list-${_uid}-${i}`"
                v-html="banner.title"
                class="t-caps -xs -primary"
            ></span>

        </template>
    </div>
</template>

<script>

import AnimText from 'objects/AnimText'

export default {
    name: 'BannersList',
    components: {
        AnimText,
    },
    props: {
        items: {
            type: Array,
            default: () => []
        },
        displayLogo: {
            type: Boolean,
            default: false
        }
    }
};

</script>

<style lang="scss">

.o-banners-list {
    display: flex;
    flex-wrap: wrap;

    span {

        &:not(:last-child) {

            &:after {
                content: "-";
                display: inline-block;
                padding-right: .5em;
                padding-left: .5em;
            }
        }
    }

    &.has-logo {
        display: flex;
        align-items: center;
        gap: 2em;
        margin: 0 0 2em;

        img {
            width: auto;
            height: auto;
            max-width: min(12em, 24vw);
            max-height: min(3em, 5vw);
        }
    }
}

</style>
