export const SECTION_HANDLES = Object.freeze({
    HOME: "home",
    PAGES: "pages",
    ABOUT: "about",
    STORES: "stores",
    STORE: "store",
    BANNER: "banner",
    JOBS: "jobs",
    JOB: "job",
    IMPLICATIONS: "implications",
    PARTNER: "partner",
    ARTICLES: "articles",
    ARTICLE: "article",
    CONTACT: "contact"
});

export const STATIC_ROUTES = Object.freeze({
    PARTNERSHIP: "/demande-de-commandite",
    SIMPLEJOB: "/candidature-spontanee"
});

// export const HERO_DISPLAYS = Object.freeze({
//     IMAGE_LEFT: 'image-left',
//     IMAGE_RIGHT: 'image-right',
//     IMAGE_UP: 'image-up',
//     OVERLAP: 'overlap',
// })

export const IMAGE_DECO = Object.freeze({
    TRACKS: "tracks",
    BASEBALL: "baseball",
    BASKETBALL: "basketball",
    FOOTBALL: "football",
    HOCKEY: "hockey",
    MOUNTAIN: "mountain"
});

export const MODAL_COMPONENTS = Object.freeze({
    JOB: "JobModal",
    SIMPLEJOB: "SimpleJobModal",
    STORE: "StoreModal",
    ARTICLE: "ArticleModal",
    PARTNERSHIP: "PartnershipModal"
});
