<template>
    <div class="m-job">
        <div v-if="data.cover" class="l-modal__cover">
            <span v-if="store.region" class="l-modal__label">
                {{ store.region.title }}
            </span>
            <asset :asset="data.cover" :radius="true" />
        </div>
        <grid-content class="l-modal__content | m-job__content">
            <template #left>
                <div class="t-content">
                    <banners-list :items="banners" />
                    <span class="t-t5">{{ store.title }}</span>
                    <h2 class="t-t3">{{ data.title }}</h2>
                    <h3 v-if="data.department" class="m-job__department | t-caps">{{ data.department.title }}</h3>
                    <div class="m-job__employment-type" v-if="data.employmentType.length">
                        <tag v-for="(type, i) in data.employmentType" :label="type" :key="`employmentType-${i}`" />
                    </div>
                    <span class="l-modal__framed">
                        <span v-if="dateStart">À partir du {{ dateStart }}</span>
                    </span>
                </div>
            </template>
            <template #right>
                <list tag="div" :items="[{ data: data.tasks }, { data: data.conditions }]">
                    <template #default="item">
                        <div class="t-cms" v-html="item.data"></div>
                    </template>
                </list>
            </template>
        </grid-content>

        <app-form
            v-if="forms"
            formName="Recrutement"
            formTemplate="job"
            :toEmail="forms.jobRecipient"
            subject="Réception d’une nouvelle candidature"
            :inputs="inputs"
            submitLabel="Postuler"
            class="m-job__form"
        >
            <template #success>
                <div class="t-cms" v-html="forms.jobConfirmation" />
            </template>
        </app-form>

        <push
            v-if="push && push.count > 1"
            :title="push.title"
            btnLabel="Découvrir les offres d'emploi"
            :btnLink="push.path"
            :asset="push.image"
        />
    </div>
</template>

<script>
import GridContent from "layout/GridContent";
import Asset from "objects/Asset";
import BannersList from "objects/BannersList";
import Tag from "objects/Tag";
import List from "components/List";
import Push from "components/Push";
import AppForm from "components/AppForm";

import { SECTION_HANDLES } from "src/constants";
import { linkResolver } from "src/router";

import { mapState } from "vuex";

export default {
    name: "JobModal",
    components: {
        GridContent,
        Asset,
        BannersList,
        List,
        Push,
        AppForm,
        Tag
    },
    props: {
        data: Object
    },
    data: () => ({
        inputs: [
            {
                title: "Formulaire"
            },
            {
                label: "Offre",
                name: "offer",
                type: "text",
                required: true,
                disabled: true
            },
            {
                label: "Prénom",
                name: "firstName",
                type: "text",
                required: true,
                autocomplete: "given-name",
                half: true
            },
            {
                label: "Nom",
                name: "lastName",
                type: "text",
                required: true,
                autocomplete: "family-name",
                half: true
            },
            {
                label: "Courriel",
                name: "fromEmail",
                type: "email",
                required: true,
                autocomplete: "email",
                half: true
            },
            {
                label: "Téléphone",
                name: "phone",
                type: "tel",
                required: true,
                autocomplete: "tel",
                half: true
            },
            {
                label: "Qui êtes-vous ?",
                name: "body",
                type: "textarea"
            },
            {
                label: "Curriculum vitae (PDF, JPG, PNG sont acceptés)",
                type: "file",
                name: "attachment[]"
            },
            {
                label: "Autre document (PDF, JPG, PNG sont acceptés)",
                type: "file",
                name: "attachment[]"
            }
        ]
    }),
    created() {
        const idx = this.inputs.findIndex(f => f.name == "offer");
        this.$set(this.inputs[idx], "defaultValue", `#${this.data.refNumber} – ${this.data.title}`);
    },
    computed: {
        ...mapState({
            forms: state => state.global.forms,
            jobs: state => state.jobs.elements
        }),

        region() {
            return this.$store.getters["global/getRegionById"](this.store.regionID);
        },

        store() {
            return this.data.store;
        },

        banners() {
            return this.store ? this.store.banners : false;
        },

        push() {
            if (!this.region || !this.jobs) {
                return false;
            }

            // Clean jobs object
            const jobs = this.jobs.filter(j => j !== undefined);

            const jobsRegionIds = jobs.map(j => (j.store ? j.store.regionID : -1));
            const jobsCount = jobsRegionIds.filter(id => id === this.region.id).length;
            const label = jobsCount - 1 > 1 ? `autres offres` : `autre offre`;

            return {
                count: jobsCount,
                title: `${this.region.title}<br> ${jobsCount - 1} ${label}`,
                path: `${linkResolver(SECTION_HANDLES.JOBS)}#${this.region.slug}`,
                image: this.region.image
            };
        },

        dateStart() {
            let d = this.data.dateBegin;

            if (!d) {
                return false;
            }

            d = new Date(this.data.dateBegin);

            return d.toLocaleString("fr", { day: "numeric", month: "long", year: "numeric" });
        }
    }
};
</script>

<style lang="scss">
.m-job {
    .m-job__content {
        --list-cols-md: 2;
    }

    .m-job__employment-type {
        .o-tag {
            margin-right: 0.4rem;
            margin-bottom: 0.4rem;
        }
    }

    .m-job__department {
        opacity: 0.5;
    }
}

.m-job__form {
    margin-top: vh(15);

    @media #{md("xs")} {
        width: calc(5 / 6 * 100%);
        margin-right: auto;
        margin-left: auto;
    }

    @media #{md("sm")} {
        width: calc(3 / 4 * 100%);
    }

    @media #{md("md")} {
        width: calc(2 / 3 * 100%);
    }
}
</style>
