<template>
    <tag
        :is="tag"
        class="c-info"
    >
        <span class="c-info__inner">
            <icon
                class="c-info__icon"
                :icon="icon"
            />
            <span
                class="c-info__content | t-caps -xs"
                v-html="content"
            ></span>
        </span>
    </tag>
</template>

<script>

import Icon from 'objects/Icon';

export default {
    name: 'Info',
    components: {
        Icon,
    },
    props: {
        tag: {
            type: String,
            default: 'span',
        },
        icon: {
            type: String,
            default: 'info',
        },
        content: {
            type: String,
            required: true
        }
    }
}
</script>

<style lang="scss">

.c-info {
    --info-padding-y: 1em;
    --info-padding-x: 1em;
    --info-color: inherit;
    --info-bg-color: #{$color-blue-lighter};

    display: inline-block;

}

.c-info__inner {
    display: inline-flex;
    align-items: center;
    padding: var(--info-padding-y) var(--info-padding-x);
    color: var(--info-color);
    background-color: var(--info-bg-color);
    border-radius: $border-radius-sm;
}

.c-info__icon {
    flex-shrink: 0;

    & + .c-info__content {
        margin-left: var(--info-padding-x);
    }
}

</style>
