<template>
    <div
        class="o-input-group"
        :class="`-${type}`"
    >
        <div
            v-for="(input, i) in inputs"
            v-if="input && input.id"
            :key="`input-${i}`"
            :class="className"
            ref="inputWrapper"
        >
            <input
                :id="`input-${_uid}-${i}`"
                class="o-input-check__input"
                :type="type"
                :value="input.id"
                :checked="input.checked"
                :required="input.required"
                ref="input"
                v-model="value"
            />
            <label
                :for="`input-${_uid}-${i}`"
                class="o-input-check__label"
            >
                <span
                    v-if="radio"
                    class="o-input-check__radio"
                ></span>
                <span class="o-input-check__text | t-small">
                    {{ input.title }}
                </span>
            </label>
        </div>
    </div>
</template>

<script>

export default {
    name: 'AppInputCheck',
    props: {
        inputs: {
            type: Array,
            required: true,
        },
        radio: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        value: false,
    }),
    created() {
        this.reset()
    },
    computed: {
        className() {
            let className = 'o-input-check'
            className += this.disabled ? ' is-disabled' : ''

            return className
        },
        type() {
            return this.radio ? 'radio' : 'checkbox'
        },
    },
    methods: {
        reset() {

            if(this.radio) {
                this.value = ''
            } else {
                this.value = []

                if(this.$refs.inputWrapper) {

                    this.$refs.inputWrapper.forEach(wrapper => {
                        wrapper.querySelector('input').checked = false
                    })
                }
            }
        },
    },
    watch: {
        value(val) {
            this.$emit('input', val)
        },
    }
};

</script>

<style lang="scss">

.o-input-group {

    &.-checkbox {

        .o-input-check__label {
            padding-right: .5em;
            padding-left: .5em;
            transition: all .1s ease-in;
        }

        .o-input-check__input:checked + .o-input-check__label {
            color: $color-secondary;
            background-color: $color-primary;
            border-radius: var(--input-border-radius, 0);
            transition: all .2s ease-out;
        }
    }

    &.-radio {

        .o-input-check__input:checked + .o-input-check__label {

            .o-input-check__radio:after {
                transform: scale(1);
                transition: transform .2s ease-out;
            }
        }
    }
}

.o-input-check {
    color: var(--input-color-text);

    &.is-disabled {
        color: var(--input-disabled-color-text);
        pointer-events: none;
    }
}

.o-input-check__input {
    display: none;
}

.o-input-check__label {
    display: inline-flex;
    align-items: flex-start;
    padding-top: .5em;
    padding-bottom: .5em;
    user-select: none;
    cursor: pointer;
}

.o-input-check__radio {
    display: inline-block;
    flex-shrink: 0;
    flex-grow: 0;
    width: 18px;
    height: 18px;
    margin-right: .5em;
    // border: $border-light;
    border-radius: 50%;

    &:after {
        @include pseudo-el($width: 50%, $height: 50%, $bg: $color-primary);
        position: absolute;
        top: calc(50%/2);
        left: calc(50%/2);
        border-radius: 50%;
        transform: scale(0);
        transition: transform .1s ease-in;
    }
}

.o-input-check__text {
    display: block;
}

</style>
