import { fetchApi } from 'src/graphql/config'
import queries from 'src/graphql/queries'
import parseData from 'src/store/parse'


export default class {
    constructor (type) {
        this.state = {
            elements: [],
            allPreloaded: false,
        };
        this.getters = {
            getElementById: state => id => state.elements.find(e => e.id == id),
            getElementBySlug: state => slug => state.elements.find(e => e.slug === slug),
            getElementIndexById: state => id => state.elements.findIndex(e => e.id == id)
        };
        this.actions = {

            preloadAll(store) {

                return new Promise((resolve, reject) => {

                    // Start load
                    store.dispatch('loader/startLoad', null, {root: true});
                    if(store.state.allPreloaded) {
                        resolve(store.state.elements)

                        // End load
                        store.dispatch('loader/endLoad', null, {root: true});

                    } else {
                        fetchApi(queries[`${type}PreloadAll`])
                            .then(r => {

                                // Exit on error
                                if (!r || !r.entries) {
                                    throw new Error(`${type} preload failed`)

                                } else {

                                    const elements = r.entries

                                    elements.forEach(element => {

                                        element = parseData(type, element)

                                        const index = store.getters.getElementIndexById(element.id)
                                        if ( index === -1) {
                                            element.singleFetch = false
                                            store.commit('addElement', element)
                                        } else {
                                            element.singleFetch = true
                                            store.commit('updateElement', { data: element, index })
                                        }
                                    })

                                    resolve(elements)
                                    store.commit('setAllPreloaded', true)

                                    // End load
                                    store.dispatch('loader/endLoad', null, {root: true});

                                }
                            })
                            .catch(e => {

                                reject({
                                    code: 404,
                                    message: e
                                })
                                store.dispatch('loader/endLoad', null, {root: true});
                            })
                    }
                })
            },
            loadSingle(store, slug) {

                return new Promise((resolve, reject) => {

                    // Start load
                    store.dispatch('loader/startLoad', null, {root: true});

                    // Search if element already exists
                    let element = store.getters.getElementBySlug(slug)

                    // If element already exists, resolve with the element value
                    if(typeof element !== 'undefined' && element.singleFetch) {
                        resolve(element)

                        // End load
                        store.dispatch('loader/endLoad', null, {root: true});

                    // If element doesn't exist, load it
                    } else {
                        fetchApi(queries[type](slug))

                            .then(r => {

                                // Exit on error
                                if (!r || !r.entry) {
                                    throw new Error(`Element not found`)

                                } else {

                                    // Flatten entry and add other data to object
                                    let { entry, ...data} = r // eslint-disable-line
                                    data = { ...r.entry, ...data }
                                    data = parseData(type, data)
                                    data.singleFetch = true

                                    const index = store.getters.getElementIndexById(data.id)
                                    if ( index === -1) {
                                        store.commit('addElement', data)
                                    } else {
                                        store.commit('updateElement', { data, index })
                                    }
                                    resolve(data)

                                    store.dispatch('loader/endLoad', null, {root: true});
                                }
                            })
                            .catch(e => {

                                reject({
                                    code: 404,
                                    message: e
                                })
                                store.dispatch('loader/endLoad', null, {root: true});
                            })

                    }

                })
            }

        };
        this.mutations = {
            addElement(state, data) {
                state.elements.push(data)
            },
            updateElement(state, { data, index }) {
                state.elements[index] = data
            },
            setAllPreloaded(state, value) {
                state.allPreloaded = value
            },
        };
    }
}
