<template>
    <div>
        <hero
            :title="page.title"
            :intro="page.intro"
            :image="page.image"
            titleClass="t-t1"
            display="overlap"
            :class="{'has-award' : hasAward}"
        >
            <template #before-content>
                <rollup
                    v-if="page.rollup"
                    :words="page.rollup"
                    class="t-t1"
                />
            </template>

            <template #after-inner>
                <award
                    :boxed="true"
                    class="p-home__award"
                />
            </template>
        </hero>

        <app-section class="p-home__banners">
            <template #bg>
                <deco
                    field="hockey"
                    :ball="true"
                />
            </template>
            <div class="grid__row">
                <div class="grid__col-10@xs grid__col-8@sm grid__col-6@md grid__col-5@lg">
                    <div class="t-content">
                        <anim-text
                            :text="page.bannersTitle"
                            tag="h2"
                            class="t-t2"
                        />
                        <anim-text
                            :text="page.bannersBody"
                            :cms="true"
                        />
                    </div>
                </div>
            </div>
            <card-grid
                :cards="banners"
                :slider="true"
                :prevNext="false"
            />
        </app-section>

        <app-section>
            <div class="grid__row | u-jc-between@md">
                <div class="grid__col-12 grid__col-10@xs grid__col-8@sm grid__col-5@md">
                    <div class="t-content">
                        <anim-text
                            :text="page.aboutTitle"
                            tag="h2"
                            class="t-t2"
                        />
                        <anim-text
                            :text="page.aboutBody"
                            :cms="true"
                        />
                        <btn
                            tag="router-link"
                            :href="SECTION_HANDLES.ABOUT|resolver"
                            label="Découvrir le groupe"
                            icon-after="arrow-right"
                        />
                    </div>
                </div>
                <div class="grid__col-12 grid__col-8@sm grid__col-6@md">
                    <ul
                        v-if="page.stats.length > 0"
                        class="o-stats"
                    >
                        <li
                            v-for="(stats, i) in page.stats"
                            :key="`home-stats-${i}`"
                            class="o-stats__item"
                        >
                            <anim-text
                                :text="stats.number"
                                :number="true"
                                class="o-stats__number | t-t2"
                            />
                            <span class="o-stats__label | t-caps -md">{{ stats.label }}</span>
                        </li>
                    </ul>
                </div>

                <div
                    v-if="img"
                    class="p-home__img"
                >
                    <deco
                        field="basketball"
                        :ball="true"
                    />
                    <asset
                        :asset="img"
                        :radius="true"
                    />
                    <icon icon="logo-gb-icon" />
                </div>
            </div>
        </app-section>

        <app-section :bg="true" class="p-home__implication--slider">
            <div class="t-content">
                <anim-text
                    :text="page.implicationsTitle"
                    tag="h2"
                    class="t-t3"
                />
            </div>
            <slider
                v-if="implicationSlides.length >= 3"
                :slides="implicationSlides"
                :translate="false"
                :tabs="true"
                class="p-home__implications"
            >
                <template #slide="slide">
                    <div class="t-content">
                        <span>
                            <anim-text
                                class="t-huge"
                                :number="true"
                                :text="slide.count"
                                :reveal="false"
                                :visible="slide.isActive"
                                :options="{ outDelta: -1, outDuration: .5 }"
                            />
                            <anim-text
                                :text="slide.sub"
                                :lines="true"
                                :reveal="false"
                                :visible="slide.isActive"
                                :options="{ inDelay: 1, outDelta: -1, outDuration: .5 }"
                            />
                        </span>
                        <btn
                            tag="router-link"
                            :href="slide.link"
                            :label="`Nos ${slide.title.toLowerCase()}`"
                            modifier="light"
                            icon-after="arrow-right"
                        />
                    </div>
                    <asset :asset="slide.image" />
                </template>
            </slider>
        </app-section>

        <app-section class="p-home__articles">
            <anim-text
                text="Nouvelles"
                tag="h2"
                class="t-t3"
            />
            <card-grid
                :cards="page.articles"
                :slider="true"
            />
        </app-section>

    </div>
</template>

<script>

import AnimText from 'objects/AnimText'
import Hero from 'layout/Hero';
import AppSection from 'layout/AppSection';

import Icon from 'objects/Icon';
import Deco from 'objects/Deco';
import Asset from 'objects/Asset';
import Rollup from 'objects/Rollup';

import Award from 'components/Award';
import Btn from 'components/Btn';
import Slider from 'components/Slider';
import CardGrid from 'components/CardGrid';

import { SECTION_HANDLES } from 'src/constants'
import { linkResolver } from 'src/router'

import { mapGetters, mapState } from 'vuex'

export default {
    name: 'Home',
    components: {
        AnimText,
        Hero,
        AppSection,
        Icon,
        Deco,
        Asset,
        Rollup,
        Award,
        Btn,
        Slider,
        CardGrid
    },
    metaInfo() {
        return {
            title: this.page.seo ? this.page.seo.title : null
        }
    },
    props: {
        page: Object,
    },
    data: () => ({
        SECTION_HANDLES,
    }),
    computed: {
        ...mapGetters({
            hasAward: 'global/hasAward',
        }),
        ...mapState({
            banners: state => state.global.banners,
            partnersCategory: state => state.global.partnerCategories,
        }),
        img() {
            const img = this.page.aboutImage

            return img.length > 0 ? img[0] : false
        },
        implicationSlides() {
            const slides = []

            const implicationsPath = linkResolver(SECTION_HANDLES.IMPLICATIONS)

            slides.push({
                title: 'Causes sociales',
                sub: 'Causes sociales qui nous tiennent à coeur.',
                count: this.page.implicationCount,
                image: this.page.implicationImage,
                link: `${implicationsPath}#causes`
            })

            this.partnersCategory.forEach(partnerCat => {
                const totalPartner = this.page.partners.filter(partner => partner.categoryID === partnerCat.id).length
                if(totalPartner > 0) {
                    slides.push({
                        title: partnerCat.title,
                        sub: partnerCat.subtitle,
                        count: totalPartner,
                        image: partnerCat.image,
                        link: `${implicationsPath}#${partnerCat.slug}`
                    })
                }
            })

            return slides
        },
    },
};

</script>

<style lang="scss">

.p-home {

    .l-hero {
        z-index: 1;

        &.has-award {
            margin-bottom: 4rem;

            .l-hero__col.-content {
                padding-bottom: 4em;
            }

        }
    }
    &__implication--slider {
        @media #{md("sm")} {
            padding-bottom: 0;
        }
    }

    .p-home__award {
        position: absolute;
        left: var(--grid-start-x);
        transform: translateY(50%);

        @media #{md("sm")} {
            bottom: 0;
        }
        @media #{md("md")} {
            left: auto;
        }
    }
}

.p-home__banners {

    .c-card-grid {
        --grid-auto-size: auto-fit;

        .c-slider {
            --slide-width: 18em;

            @media #{md("md")} {
                --slide-width: 17em;
            }
            @media #{md("lg")} {
                --slide-width: 18em;
            }
        }

        @media #{md("sm")} {
            .c-card-grid__grid {
                column-gap: var(--grid-gutter);
            }
        }

        img {
            height: 100%;
        }

    }

    .o-deco {
        --deco-size: 50vw;
        top: -18vw;
        right: 0;
        // transform: rotate(51deg);
        overflow: hidden;
    }

    .o-deco__inner {
        right: calc(-.3 * var(--deco-size));
        transform: rotate(51deg);
    }

    @media #{md("xs", "max")} {
        margin-top: 15vw;

        .o-deco {
        }
    }

    @media #{md("xs")} {

        .o-deco {
            --deco-size: 35vw;
            top: calc(-.4 * var(--deco-size));
        }

        .o-deco__inner {
            right: calc(-.4 * var(--deco-size));
        }
    }
}

.p-home__img {
    width: 100%;

    .o-deco {
        --deco-size: 50vw;
        position: relative;
        margin-left: -10vw;
        margin-bottom: -10vw;
    }

    .o-icon.-logo-gb-icon {
        --svg-width: 25vw;

        position: absolute;
        top: calc(50% - (var(--svg-width)/var(--svg-ratio))/2);
        left: calc(50% - var(--svg-width)/2);
        color: $color-light;
    }

    @media #{md("lg")} {

        .o-deco {
            --deco-size: 32em;
        }

        .o-icon.-logo-gb-icon {
            --svg-width: 18em;
        }
    }
}

.p-home__implications {
    .c-slider__slide {

        .c-btn {
            opacity: 0;
            transform: translate(0, 1em);
            transition: opacity .4s $in-quart, transform .4s $in-quart;
        }

        .c-btn__inner:before {
            transform-origin: 0 50%;
        }

        .o-asset {
            opacity: 0;
            transform: scale(.9, .75);
            transform-origin: 50% 0%;
            // transform-origin: 50% 100%;
            transition: opacity .4s $in-quart, transform .4s $in-quart;
        }

        &.is-selected {

            .c-btn {
                opacity: 1;
                transform: translate(0);
                transition: opacity .7s $out-quart .3s, transform .7s $out-quart .3s;
            }

            .o-asset {
                opacity: 1;
                transform: scale(1);
                transform-origin: 50% 100%;
                // transform-origin: 50% 100%;
                transition: opacity .7s $out-quart .3s, transform .7s $out-quart .3s;
            }
        }
    }

    @media #{md("sm", "max")} {
        margin-top: 2em;

        .c-slider__slide {
            display: flex;
            flex-direction: column-reverse;
            justify-content: flex-end;

            .o-asset {
                margin-bottom: 2em;
            }
        }
    }

    @media #{md("sm")} {
        --slide-columns: 1fr 1fr;

        .c-slider__slide {
            display: grid;
            grid-column-gap: var(--grid-gutter);
            grid-template-columns: var(--slide-columns);
        }
    }

    @media #{md("md")} {
        --slide-columns: 1fr 2fr;
    }

    @media #{md("lg")} {
        --slide-columns: 1fr 3fr;
    }
}

.p-home__articles {

    .c-slider__nav:after {
        background-color: $color-primary;
    }
}


/*==================================
=            Statistics            =
==================================*/

.o-stats {
    @include reset-list;

    @media #{md("md", "max")} {
        margin-top: 2em;
    }
}

.o-stats__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 1.5em;
    padding-bottom: 1.5em;

    &:before {
        @include pseudo-el($height: 2px, $bg: $color-orange);
        position: absolute;
        top: 0;
        left: 0;
        opacity: .4;
    }
}

.o-stats__label {
}


</style>
