<template>
    <div>
        <hero
            :title="page.title"
            :intro="page.intro"
            :image="page.image"
            display="image-left"
        >
            <template #before-content>
                <deco
                    class="p-stores__deco"
                    field="football"
                />
            </template>
            <template #after-content>
                <div class="p-stores__banners">
                    <div
                        v-for="(row, i) in groupedBanners"
                        :key="i"
                        class="p-stores__banners-group"
                    >
                        <asset
                            v-for="banner in row"
                            :key="`banners-${banner.id}`"
                            :asset="banner.logo"
                        />
                    </div>
                    <!-- <div>
                        <asset
                            v-for="banner in banners1"
                            :key="`banners-${banner.id}`"
                            :asset="banner.logo"
                        />
                    </div>
                    <div>
                        <asset
                            v-for="banner in banners2"
                            :key="`banners-${banner.id}`"
                            :asset="banner.logo"
                        />
                    </div> -->
                </div>
            </template>
        </hero>

        <section-list
            v-if="sections.length > 0"
            :sections="sections"
        />
    </div>
</template>

<script>

import Hero from 'layout/Hero';
import Deco from 'objects/Deco';
import Asset from 'objects/Asset';
import SectionList from 'components/SectionList';

import { SECTION_HANDLES, MODAL_COMPONENTS } from 'src/constants'

import { mapState } from 'vuex'

export default {
    name: 'Stores',
    components: {
        Hero,
        Deco,
        Asset,
        SectionList,
    },
    props: {
        page: Object,
    },
    metaInfo() {
        return {
            title: this.page.seo ? this.page.seo.title : null
        }
    },
    created() {
        this.pageInit()
    },
    computed: {

        ...mapState({
            banners: state => state.global.banners,
            regions: state => state.global.regions,
            stores: state => state.stores.elements
        }),
        groupedBanners() {
            const rowLength = 2;
            let rows = [],
                currentRow = [];
            this.banners.map((li, i) => {
                if (i % rowLength === 0 && i !== 0) {
                    rows.push(currentRow);
                    currentRow = [];
                }
                currentRow.push(li);
            });
            rows.push(currentRow);
            return rows;
        },

        isStore() {
            return this.$route.meta.section === SECTION_HANDLES.STORE
        },

        sections() {
            const sections = []

            this.regions.forEach(region => {
                const storesInRegion = this.stores.filter(store => store.regionID == region.id)

                if(storesInRegion.length > 0) {
                    sections.push({
                        ...region,
                        cards: storesInRegion
                    })
                }
            })

            return sections
        }
    },
    methods: {
        pageInit() {

            // Load store and open modal
            if (this.isStore) {

                // Load store data
                this.$store.dispatch('stores/loadSingle', this.$route.params.slug)
                    .then(store => {
                        this.$store.dispatch('modal/open', { component: MODAL_COMPONENTS.STORE, data: store })
                    })
            }
        },
    },
    watch: {
        $route() {
            this.pageInit()
        },
    }
};

</script>

<style lang="scss">

.p-stores__deco {
    --deco-size: 25vw;
    --deco-color: #{$color-orange};

    top: 0;
    right: 0;
    overflow: hidden;

    .o-deco__inner {
        top: -2vw;
        right: -2vw;
    }
}

.p-stores__banners {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 2em;

    &-group {
        display: flex;
        flex-basis: 5em;
    }

    .o-asset {
        margin-right: 1em;
        margin-bottom: 2em;

        &__img {
            width: 8.5em;
            height: 2.5em;
            object-fit: contain;
            object-position: left center;

            @media #{md("lg")} {
                height: 2em;
            }
        }
    }
}
</style>
