<template>
    <component
        v-if="text"
        :is="cms ? 'div' : tag"
        :class="className"
        v-html="text"
        v-reveal.once="reveal ? revealHandle : false"
    />
</template>

<script>

import { gsap, SplitText } from 'gsap/all'
gsap.registerPlugin(SplitText);

export default {
    name: 'AnimText',
    props: {
        text: {
            type: String|Number,
            default: null,
        },
        tag: {
            type: String,
            default: 'span',
        },
        reveal: {
            type: Boolean,
            default: true,
        },
        visible: {
            type: Boolean,
            default: false,
        },
        cms: {
            type: Boolean,
            default: false,
        },
        number: {
            type: Boolean,
            default: false,
        },
        lines: {
            type: Boolean,
            default: false,
        },
        options: {
            type: Object,
            default: () => {}
        },
    },
    data: () => ({
        opts: {
            inDuration: 1,
            inDelta: 1,
            idDelay: 0,
            outDuration: 0.001,
            outDelta: 1,
            outDelay: 0,
        },
    }),
    created() {
        this.opts = {...this.opts, ...this.options}
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {

            if(this.cms) {
                return
            }

            const type = this.number ? 'lines,chars' : 'lines,words'

            this.split = new SplitText(this.$el, {
                type,
                charsClass: 'o-at__c',
                wordsClass: 'o-at__w',
                linesClass: 'o-at__l',
                reduceWhiteSpace: false,
            })


            let outStagger = 0
            if(this.number || this.lines) {
                outStagger = {
                    each: .1,
                    from: 'end',
                    axis: this.lines ? 'y' : null,
                }
            }

            // Initial hide
            gsap.set(this.splitItems, { opacity: 0 })


            // Set timeline
            this.tl = gsap
                        .timeline({ paused: true })
                        .addLabel('show')
                        .set(this.splitItems, {
                            opacity: 1,
                            yPercent: this.opts.inDelta * 100,
                            delay: this.opts.inDelay,
                        })
                        .to(this.splitItems, {
                            duration: this.opts.inDuration,
                            yPercent: 0,
                            stagger: {
                                each: .1,
                                from: this.number ? 'end' : 'start',
                                axis: this.lines ? 'y' : null,
                            },
                            ease: this.number ? 'power3.inOut' : 'power3.out',
                            // overwrite: 'all',
                        })
                        .addPause()
                        .addLabel('hide')
                        .to(this.splitItems, {
                            duration: this.opts.outDuration,
                            delay: this.opts.outDelay,
                            stagger: outStagger,
                            yPercent: this.opts.outDelta * 110,
                            ease: 'power3.in',
                            // overwrite: 'all',
                        })
                        .set(this.splitItems, {
                            opacity: 0,
                        })

                if(this.visible) {
                    this.show()
                }

        },
        revealHandle(state) {
            if(state.isActive) {
                this.show()
            } else {
                this.hide()
            }
        },
        show() {

            if(this.cms) {
                return
            }

            this.tl.play('show');
        },
        hide() {

            if(this.cms) {
                return
            }

            this.tl.play('hide');
        },
    },
    computed: {
        className() {
            let classname = 'o-at'

            if(this.reveal) {
                classname += ' js-reveal'
            }

            if(this.isVisible) {
                classname += ' is-visible'
            }

            if(this.cms) {
                classname += ' t-cms'
            }

            if(this.number) {
                classname += ' -number'
            }

            return classname
        },
        splitItems() {
            return this.number ? this.split.chars : this.split.words
        },
    },
    watch: {
        visible(visible) {
            if(visible) {
                this.show()
            } else {
                this.hide()
            }
        },
    }
};

</script>

<style lang="scss">


.o-at {
    display: block;

    // &.t-cms {
    //     @include anim-reveal-default;
    // }

    &.-number {

        .o-at__l {
            margin-top: 0;
            padding-top: 0;
        }
    }

    html.reduced-motion & {

        &.t-cms > *,
        .o-at__w {
            transition: none !important;
        }
    }
}

.o-at__l {
    margin-top: -.2em;
    padding-top: .2em;
    overflow: hidden;
}

.o-at__c,
.o-at__w {
    // transform: translate(0, 100%) rotate3d(0, 0, 1, 25deg);
    // opacity: 0;
    transform-origin: 0 100%;
    will-change: transform;
}

</style>
