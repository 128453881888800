<template>
    <div v-if="inputs.length > 0" class="c-form" :class="{ 'is-sending': pending }">
        <slot name="before" />

        <div v-if="error" class="c-form__error | t-cms">
            <p>Une erreur est survenue. Veuillez vérifier tous les champs et re-essayer de nouveau.</p>
            <p>Si le problème persiste, n’hésitez pas à nous contacter.</p>
        </div>

        <form v-if="!success" action="/" class="c-form__inner" @submit.prevent="submitForm">
            <template v-for="(input, i) in inputs">
                <anim-text
                    v-if="input.title"
                    :key="`anim-text-${i}`"
                    :text="input.title"
                    tag="h2"
                    class="c-form__heading | t-t4"
                />
                <div
                    v-else
                    :key="`input-${input.name}-${i}`"
                    class="c-form__el"
                    :class="[{ 'has-error': input.error }, { '-half': input.half }]"
                >
                    <app-select
                        v-if="input.options"
                        v-model="input.value"
                        :name="input.name"
                        :options="input.options"
                        :placeholder="input.label"
                        :required="!!input.required"
                        as="title:title"
                    />

                    <app-input-file
                        v-else-if="input.type === 'file'"
                        v-model="input.value"
                        :label="input.label"
                        :required="!!input.required"
                    />

                    <app-input-boolean
                        v-else-if="input.type === 'boolean'"
                        v-model="input.value"
                        :label="input.label"
                        :required="!!input.required"
                    />

                    <app-input-text
                        v-else
                        v-model="input.value"
                        :label="input.label"
                        :name="input.name"
                        :type="input.type"
                        :autocomplete="input.autocomplete"
                        :required="!!input.required"
                        :defaultValue="input.defaultValue"
                        :disabled="!!input.disabled"
                    />
                </div>
            </template>

            <div v-if="showConsent" class="c-form__consent">
                <app-input-boolean
                    v-model="privacyConsentInput.value"
                    :label="consentLabel || privacyConsentInput.label"
                    :required="privacyConsentInput.required"
                />
                <div v-if="privacyConsentInput.error" class="c-form__error | t-cms">
                    <p>
                        Vous devez accepter nos conditions d’utilisation et notre politique de confidentialité afin de
                        soumettre le formulaire.
                    </p>
                </div>
            </div>

            <div class="c-form__el -submit">
                <btn
                    :label="pending ? 'Envoi en cours...' : submitLabel"
                    :icon-after="pending ? null : 'arrow-right'"
                    type="submit"
                />
            </div>
        </form>

        <div v-else-if="successText || !!$slots.success" class="c-form__success">
            <slot name="success">
                <h3 class="t-t3">{{ successText }}</h3>
            </slot>
        </div>

        <slot name="after" />
    </div>
</template>

<script>
import AnimText from "objects/AnimText";
import AppSelect from "objects/AppSelect";
import AppInputCheck from "objects/AppInputCheck";
import AppInputFile from "objects/AppInputFile";
import AppInputText from "objects/AppInputText";
import AppInputBoolean from "objects/AppInputBoolean";

import Btn from "components/Btn";

export default {
    name: "AppForm",
    components: {
        AnimText,
        AppSelect,
        AppInputCheck,
        AppInputFile,
        AppInputText,
        AppInputBoolean,
        Btn
    },
    props: {
        formName: false,
        formTemplate: {
            type: String,
            required: true
        },
        inputs: {
            type: Array,
            default: () => [
                {
                    label: "Prénom",
                    name: "firstName",
                    type: "text",
                    required: true,
                    autocomplete: "given-name",
                    half: true
                },
                {
                    label: "Nom",
                    name: "lastName",
                    type: "text",
                    required: true,
                    autocomplete: "family-name",
                    half: true
                },
                {
                    label: "Courriel",
                    name: "fromEmail",
                    type: "email",
                    disabled: false,
                    required: true,
                    autocomplete: "email",
                    half: true
                },
                {
                    label: "Message",
                    name: "body",
                    type: "textarea"
                }
            ]
        },
        subject: {
            type: String,
            default: "Nouvelle soumission de formulaire"
        },
        successText: {
            type: String,
            default: "Votre demande a bien été envoyée"
        },
        submitLabel: {
            type: String,
            default: "Envoyer"
        },
        toEmail: {
            type: String,
            default: "info@groupebouchersports.com"
        }
    },
    data: () => ({
        data: {
            message: {}
        },
        error: false,
        success: false,
        pending: false,
        exceptions: ["fromName", "fromEmail", "subject", "attachment[]"],
        showConsent: true,
        privacyConsentInput: {
            label: "J’accepte les conditions d'utilisation et la politique de confidentialité.",
            required: true,
            value: false,
            error: false
        }
    }),
    computed: {
        csrfName: () => window.csrfTokenName,
        csrfToken: () => window.csrfTokenValue,
        contactFirstName() {
            return this.inputs.filter(i => i.name == "firstName")[0].value;
        },
        contactLastName() {
            return this.inputs.filter(i => i.name == "lastName")[0].value;
        },
        contactFullName() {
            return `${this.contactFirstName} ${this.contactLastName}`;
        },
        policyPageUrl() {
            return window.policyPageUrl;
        },
        consentLabel() {
            return `J’accepte les <a href="${this.policyPageUrl}" target="_blank">conditions d’utilisation et la politique de confidentialité</a>.`;
        }
    },
    created() {
        this.reset();
    },
    methods: {
        submitForm() {
            if (this.pending) {
                return;
            }

            // reset
            this.success = false;
            this.error = false;

            this.inputs.forEach((input, i) => {
                this.inputs[i].error = false;
            });

            this.pending = true;

            // Create form data
            const formData = new FormData();

            formData.append("action", "contact-form/send");

            // Add hidden inputs
            formData.append(this.csrfName, this.csrfToken);
            formData.append("fromName", this.contactFullName);
            formData.append("message[template]", this.formTemplate);

            if (this.toEmail !== null) formData.append("toEmail", this.toEmail);

            if (this.subject) {
                formData.append("subject", this.subject);
            }
            if (this.formName) {
                formData.append("message[formName]", this.formName);
            }

            if (this.showConsent && this.privacyConsentInput.value === false) {
                this.privacyConsentInput.error = true;
                this.pending = false;
                return;
            }

            this.inputs.forEach((input, i) => {
                if (typeof input.value !== "undefined") {
                    // Validation
                    if (input.value === "" && input.required) {
                        this.error = true;
                        this.pending = false;
                        this.inputs[i].error = true;
                    }

                    // Prepend input to formData
                    if (!this.exceptions.includes(input.name)) {
                        //data.message[fieldName] = input.value
                        formData.append(`message[${input.name}]`, input.value);
                    } else {
                        //data[fieldName] = input.value
                        formData.append(input.name, input.value);
                    }
                }
            });

            if (!this.error) {
                fetch("/contact-form/send", {
                    method: "POST",
                    body: formData
                })
                    .then(r => {
                        if (r.ok) {
                            return r.text();
                        } else {
                            this.pending = false;
                            this.error = true;
                            throw new Error("Something went wrong.");
                        }
                    })
                    .then(() => {
                        this.reset();

                        this.success = true;
                        this.pending = false;

                        this.$emit("success");
                    })
                    .catch(e => {
                        console.error("Request failed", e); // eslint-disable-line
                    });
            }
        },
        reset() {
            this.success = false;
            this.inputs.forEach((input, i) => {
                input.value = "";
                this.inputs[i].error = false;
            });
        }
    }
};
</script>

<style lang="scss">
.c-form {
    --input-height: 3.75em;

    --input-border-color: #{$color-secondary};
    --input-border-radius: #{$border-radius-sm};

    --input-color-text: #{$color-dark};
    --input-color-bg: #{$color-light};
    --input-option-color-bg: #{$color-light};

    --input-disabled-color-text: #{$color-light};
    --input-disabled-color-bg: #{$color-primary};

    --input-checked-color-text: #{$color-light};
    --input-checked-color-bg: #{$color-primary};

    margin-top: 2em;
    margin-bottom: 2em;

    &.is-sending {
        cursor: wait;
        pointer-events: none;
        opacity: 0.8;
    }
}

.c-form__error {
    padding: 1em;
    margin-bottom: 1em;
    background: rgba(red, 0.06);
    border-radius: $border-radius-sm;
}

.c-form__inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.c-form__heading {
    width: 100%;
    margin-bottom: 0.75em;

    &:not(:first-child) {
        margin-top: 2em;
    }
}

.c-form__el {
    width: 100%;
    flex-shrink: 0;
    flex-grow: 0;
    margin-top: 1em;
    margin-bottom: 1em;

    &.has-error {
        --input-border-color: red;
    }

    &.-submit {
        display: flex;
        justify-content: center;
    }

    @media #{md("sm")} {
        &.-half {
            width: calc(50% - var(--grid-gutter));
        }
    }
}

.c-form__consent {
    display: flex;
    flex-direction: column;
    gap: 1em;
}
</style>
