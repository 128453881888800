<template>
    <div>

        <hero
            :title="page.title"
            :intro="page.intro"
            :image="page.image"
            display="image-right"
        >
            <template #before-image>
                <deco
                    class="p-implications__deco"
                    field="tracks"
                />
                <deco
                    class="p-implications__deco"
                    field="tracks"
                />
            </template>
            <template #after-content>
                <div class="c-list-inline">
                    <btn
                        :label="`${totalImplications} causes sociales`"
                        modifier="underline"
                        @click.native="scrollTo('#causes')"
                    />
                    <btn
                        v-for="partnerType in groupedPartners"
                        :key="`partner-anchor-${partnerType.slug}`"
                        :label="`${partnerType.count} ${partnerType.title.toLowerCase()}`"
                        modifier="underline"
                        @click.native="scrollTo(`#${partnerType.slug}`)"
                    />
                </div>
            </template>
        </hero>

        <app-section>
            <grid-content
                v-if="totalImplications > 0"
                id="causes"
                :sticky="true"
            >
                <template #left>
                    <h2 class="p-implications__heading">
                        <span class="t-huge">{{ totalImplications }}</span>
                        <span class="t-t5-bold">principales causes sociales</span>
                    </h2>
                </template>
                <template #right>
                    <accordion
                        v-for="(implication, i) in page.implications"
                        :key="`implication-accordeon-${i}`"
                        :content="implication.body"
                        class="p-implications__accordion"
                    >
                        <template #title>
                            <asset
                                :asset="implication.logo"
                                :contain="true"
                                class="c-accordion__logo"
                            />
                             <h3 class="c-accordion__title">
                                {{ implication.title }}
                            </h3>
                        </template>
                    </accordion>
                </template>
            </grid-content>

            <grid-content
                v-for="partnerType in groupedPartners"
                :key="`partner-section-${partnerType.slug}`"
                :id="partnerType.slug"
                :sticky="true"
            >
                <template #left>
                    <h2 class="p-implications__heading">
                        <span class="t-huge">{{ partnerType.count }}</span>
                        <span class="t-t5-bold">principaux {{ partnerType.title }}</span>
                    </h2>
                    <p
                        v-if="partnerType.body"
                        v-html="partnerType.body"
                        class="t-small"
                    ></p>
                </template>
                <template #right>
                    <list
                        :items="partnerType.partners"
                        :border="true"
                        class="p-implications__partners-list"
                    >
                        <template #default="partner">
                            <component
                                :is="partner.website ? 'a' : 'div'"
                                :title="partner.website ? `${partner.title}, visitez le site internet` : null"
                                :href="partner.website"
                            >
                                <asset
                                    :asset="partner.logo"
                                    :contain="true"
                                />
                                <h3>
                                    {{ partner.title }}
                                </h3>
                            </component>

                            <span
                                v-if="partner.store.length"
                                class="t-caps -xs -primary"
                            >
                               {{ partner.store[0].title }}
                            </span>

                        </template>
                    </list>
                </template>
            </grid-content>
        </app-section>
    </div>
</template>

<script>

import Hero from 'layout/Hero';
import AppSection from 'layout/AppSection';
import GridContent from 'layout/GridContent';

import Deco from 'objects/Deco';
import Asset from 'objects/Asset';

import Accordion from 'components/Accordion';
import Btn from 'components/Btn';
import List from 'components/List';

import { mapState } from 'vuex'
import { gsap, ScrollToPlugin } from 'gsap/all'
gsap.registerPlugin(ScrollToPlugin);

export default {
    name: 'Implications',
    components: {
        Hero,
        AppSection,
        GridContent,
        Deco,
        Asset,
        Accordion,
        Btn,
        List,
    },
    props: {
        page: Object,
    },
    metaInfo() {
        return {
            title: this.page.seo ? this.page.seo.title : null
        }
    },
    computed: {
        ...mapState({
            partnerCategories: state => state.global.partnerCategories,
        }),

        groupedPartners() {
            const array = []
            let partners, count

            this.partnerCategories.forEach(cat => {
                partners = this.page.partners.filter(p => p.type === cat.id)
                count = partners.length

                if(count > 0) {
                    array.push({
                        ...cat,
                        partners,
                        count,
                    })
                }
            })

            return array
        },

        totalImplications() {
            return this.page.implications.length
        },
    },
    methods: {
        scrollTo(target) {
            gsap.to(window, {
                duration: 1,
                scrollTo: target,
                ease: 'expo.inOut',
            })
        },
    },
};

</script>

<style lang="scss">

.p-implications {

    .l-grid-content {
        padding-top: 2em;
        margin-bottom: vh(15);

        html.is-mobile-safari & {
            margin-bottom: 7.75rem;
        }
    }

    .l-hero {
        .c-list-inline {
            margin-top: 2em;
        }
    }

    @media #{md("md")} {
        .l-hero {
            &.-image-right {
                .l-hero__col {
                    &.-content {
                        padding-right: calc(0.5/12 * 100%);

                        .l-hero__intro {
                            padding-right: calc(1.5/12 * 100%);
                        }
                    }
                }
            }
        }
    }

    .c-list__item {
        .o-asset {
            width: 6rem;
            height: 6rem;
            margin-bottom: 1rem;

            .o-asset__img {
                object-position: left;
            }
        }
    }
}

.p-implications__deco {
    --deco-size: 25vw;
    --deco-color: #{$color-orange};

    &:nth-child(1) {
        bottom: -7vw;
        left: 1em;
        transform: rotate(90deg);
    }

    &:nth-child(2) {
        z-index: 1;
        top: 0;
        right: 0;
        overflow: hidden;
        mix-blend-mode: screen;

        .o-deco__inner {
            top: calc(var(--deco-size) * -.5);
            right: calc(var(--deco-size) * -.5);
        }
    }

    @media #{md("md")} {
        --deco-size: 17vw;

        &:nth-child(1) {
            bottom: 1em;
            left: 0em;
        }
    }

    @media #{md("lg")} {
        --deco-size: 16em;
    }
}

.p-implications__heading {
    display: flex;
    flex-direction: column;
    font-weight: 700;
    color: $color-orange;
}


.p-implications__accordion {

    &.is-open {

        .c-accordion__container {
            transform: translate(0, -1em);
            transition: transform .8s $out-expo;
        }
    }

    .c-accordion__header {
        display: grid;
        grid-template-columns: 4em 1fr 2.5em;
        grid-gap: var(--grid-gutter);

        @media #{md("sm")} {
            grid-template-columns: 3.5em 1fr 2.5em;
        }
        @media #{md("md")} {
            grid-template-columns: 5em 1fr 3.5em;
        }
    }

    .c-accordion__logo {
        height: 5.5em;

        .o-asset__img {
            object-position: 0 50%;
        }
    }

    .c-accordion__title {
        grid-column-start: 2;
    }

    .c-accordion__container {
        transition: transform .4s $out-expo;
    }

    .c-accordion__content {

        padding-left: 0;
        padding-right: var(--grid-gutter-half);

        @media #{md("sm")} {
            padding-right: 2.5em;
            padding-left: calc( 3.5em + var(--grid-gutter));
        }

        @media #{md("md")} {
            padding-right: 3.5em;
            padding-left: calc(5em + var(--grid-gutter));
        }
    }
}

.p-implications__partners-list {
    .c-list__item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        & > a, & > span {
            display: block;
        }

        & > span {
            margin-top: 3rem;
        }
        a {
            transition: color .4s ease;
            &:hover {
                color: $color-primary;
            }
        }
    }
}

</style>
