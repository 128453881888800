<template>
    <div
        :class="className"
    >
        <loader />

        <app-header @toggleNav="toggleNav" />

        <main class="l-main" ref="main">
            <router-view
                v-if="hasCurrentPage"
                :page="currentPage"
            />
        </main>

        <app-modal />

        <app-footer />
    </div>
</template>

<script>

import AppHeader from 'layout/AppHeader';
import AppModal from 'layout/AppModal';
import AppFooter from 'layout/AppFooter';

import Loader from 'objects/Loader';

import { mapState, mapGetters } from 'vuex'

export default {
    name: 'AppBase',
    components: {
        AppHeader,
        AppModal,
        AppFooter,
        Loader,
    },
    data: () => ({
        navIsOpen: false,
    }),
    computed: {
        ...mapState({
            currentPage: state => state.global.currentPage,
            currentPageName: state => state.global.currentPageName,
        }),
        ...mapGetters({
            hasCurrentPage: 'global/hasCurrentPage',
        }),
        className() {
            let classname = 'l-wrapper'

            if (this.currentPageName) {
                classname += ` p-${this.currentPageName}`
            }

            // Add nav state
            if (this.navIsOpen) {
                classname += ' nav-is-open'
            }

            return classname
        },
    },
    methods: {
        toggleNav(isOpen) {
            this.navIsOpen = isOpen
        },
    }
}

</script>

<style lang="scss">

.l-wrapper {}

.l-main {}

</style>
