<template>
    <div class="m-job">
        <div
            v-if="data.cover"
            class="l-modal__cover"
        >
            <span
                v-if="data.category"
                class="l-modal__label"
            >
                {{ data.category }}
            </span>
            <asset
                :asset="data.cover"
                :radius="true"
            />
        </div>

        <grid-content class="l-modal__content | m-job__content">
            <template #left>
                <div class="t-content">
                    <h2 class="t-t2">{{ data.title }}</h2>
                    <span class="l-modal__framed">
                        <span>Publié</span>
                        <span class="u-tt-lowercase">{{ data.date }}</span>
                    </span>
                </div>
            </template>
            <template #right>
                <div
                    class="t-cms"
                    v-html="data.body"
                ></div>
            </template>
        </grid-content>

        <push
            :title="push.title"
            btnLabel="Voir les nouvelles"
            :btnLink="push.path"
            :asset="push.image"
        />
    </div>
</template>

<script>

import GridContent from 'layout/GridContent';
import Asset from 'objects/Asset';
import Push from 'components/Push';

import { SECTION_HANDLES } from 'src/constants'
import { linkResolver } from 'src/router'

import { mapGetters } from 'vuex'

export default {
    name: 'ArticleModal',
    components: {
        GridContent,
        Asset,
        Push,
    },
    props: {
        data: Object,
    },
    data() {
        return {
            pageArticles: {}
        }
    },
    created() {
        this.pageArticles = this.getPageByHandle('articles')
    },
    computed: {
        ...mapGetters({
            getPageByHandle: 'pages/getPageByHandle'
        }),

        push() {
            if( !this.pageArticles )
                return

            return {
                title: `Découvrir les autres nouvelles`,
                path: `${linkResolver(SECTION_HANDLES.ARTICLES)}#${this.data.year}`,
                image: this.pageArticles.image && this.pageArticles.image.length ? this.pageArticles.image[0] : {}
            }
        },
    }
};

</script>

<style lang="scss">

.m-job {}

.m-job__details {
    display: inline-flex;
    flex-wrap: wrap;
    background-color: $color-secondary;
    border-radius: $border-radius-sm;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;

    span {
        display: inline-block;
        flex-grow: 1;
        padding: 1em;

        &:nth-child(2) {

            &:before {
                @include pseudo-el($height: 1px, $bg: $color-primary);
                position: absolute;
                bottom: 100%;
                left: 0;
            }

            &:after {
                @include pseudo-el($width: 1px, $bg: $color-primary);
                position: absolute;
                top: 0;
                left: -1px;
            }
        }
    }
}

.m-job__content {
    --list-cols-md: 2;

    .t-t2 {
        text-transform: unset;
        font-weight: 600;
    }
}

.m-job__form {
    margin-top: vh(15);

    @media #{md("xs")} {
        width: calc(5/6 * 100%);
        margin-right: auto;
        margin-left: auto;
    }

    @media #{md("sm")} {
        width: calc(3/4 * 100%);
    }

    @media #{md("md")} {
        width: calc(2/3 * 100%);
    }
}

</style>
