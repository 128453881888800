<template>
    <article :class="className">
        <div class="c-card__inner">
            <!-- JOB -->
            <template v-if="isJob">
                <div class="c-card__content">
                    <banners-list v-if="data.store" :items="data.store.banners" />
                    <h3 v-if="data.store" class="c-card__title | t-t5">
                        {{ data.store.title }}
                    </h3>
                    <h4 class="t-caps -xs">{{ data.title }}</h4>
                    <h4 class="t-caps -xs -primary">{{ data.employmentType }}</h4>
                </div>

                <div class="c-card__asset">
                    <asset :asset="data.cardImage" :cover="true" />
                </div>

                <div class="c-card__buttons" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave">
                    <btn tag="router-link" :href="link" label="Postuler" icon-after="arrow-right" />
                </div>
            </template>

            <!-- STORE -->
            <template v-else-if="isStore">
                <div class="c-card__content">
                    <h3 class="c-card__title | t-t5">{{ data.title }}</h3>
                    <banners-list :items="data.banners" />
                    <info v-if="data.notes" class="c-card__info" :content="data.notes" />
                </div>
                <div class="c-card__asset">
                    <asset :asset="data.image" :cover="true" />
                </div>

                <div class="c-card__buttons" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave">
                    <btn
                        v-if="data.phone"
                        tag="a"
                        :href="data.phone | tel"
                        :label="`Appeler ${data.phone}`"
                        modifier="blur"
                    />

                    <btn
                        tag="router-link"
                        :href="link"
                        label="Plus d’informations"
                        icon-after="arrow-right"
                        modifier="blur"
                    />
                </div>
            </template>

            <!-- ARTICLE -->
            <template v-else-if="isArticle">
                <router-link
                    class="c-card__link"
                    :to="data.uri"
                    @mouseenter.native="handleMouseEnter"
                    @mouseleave.native="handleMouseLeave"
                />
                <span v-if="(data.image && data.image.length) || data.type == 'recruitment'" class="c-card__asset">
                    <span v-if="data.type == 'recruitment'" class="c-card__asset-count">
                        <span class="t-t1">
                            {{ relatedJobCount }}
                        </span>
                    </span>
                    <asset v-else :asset="data.image" :cover="true" />
                </span>

                <div class="c-card__content">
                    <h4
                        class="c-card__date"
                        v-html="
                            data.type == 'recruitment' ? `Poste${relatedJobCount > 1 ? 's' : ''} à combler` : data.date
                        "
                    />
                    <h3 class="c-card__title | t-t5">{{ data.title }}</h3>
                    <div class="c-card__excerpt | t-cms">
                        <p>{{ data.body | truncate(110) }}</p>
                    </div>
                </div>

                <div class="c-card__buttons">
                    <div class="c-btn -blur">
                        <div class="c-btn__inner">
                            <icon class="c-btn__icon" icon="arrow-right" />
                        </div>
                    </div>
                </div>
            </template>

            <!-- BANNER -->
            <template v-else-if="isBanner">
                <span class="c-card__asset">
                    <asset :asset="data.image" />
                </span>

                <div class="c-card__content">
                    <asset class="c-card__logo" :asset="data.logo" />
                    <h3 class="c-card__title | t-t5">{{ data.title }}</h3>
                </div>

                <div class="c-card__buttons" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave">
                    <btn
                        tag="a"
                        :href="data.website"
                        title="Consulter le site"
                        label="Consulter le site"
                        icon-after="arrow-right"
                        modifier="blur"
                    />
                </div>
            </template>
        </div>

        <!-- SIDE -->
        <div v-if="(isStore && relatedJobCount) || isBanner" class="c-card__side">
            <router-link v-if="isStore" :to="jobSectionLink(data)">
                <span v-if="sideLabel" class="c-card__side-label | t-caps">
                    {{ sideLabel }}
                </span>

                <span class="c-card__side-count | t-caps">{{ relatedJobCount }}</span>
                <icon class="c-card__side-icon" icon="arrow-right" />
            </router-link>

            <template v-else>
                <span v-if="sideLabel" class="c-card__side-label | t-caps">
                    {{ sideLabel }}
                </span>
                <template v-if="isBanner && relatedStores">
                    <span class="c-card__side-count | t-t4">{{ relatedStores }}</span>
                </template>
            </template>
        </div>
    </article>
</template>

<script>
import Icon from "objects/Icon";
import Asset from "objects/Asset";
import BannersList from "objects/BannersList";
import Btn from "components/Btn";
import Info from "components/Info";

import { linkResolver } from "src/router";
import { SECTION_HANDLES } from "src/constants";

export default {
    name: "Card",
    components: {
        Icon,
        Asset,
        BannersList,
        Btn,
        Info,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        SECTION_HANDLES,
    }),
    computed: {
        className() {
            let classname = "c-card";

            if (this.isArticle) {
                classname += " -article";

                if (this.data.type == "recruitment") classname += " -black";
            } else if (this.isBanner) {
                classname += " -banner";
            } else if (this.isStore) {
                classname += " -store";
            } else if (this.isJob) {
                classname += " -job";
            }

            return classname;
        },
        type() {
            return this.data.sectionHandle;
        },
        isArticle() {
            return this.type === SECTION_HANDLES.ARTICLE;
        },
        isBanner() {
            return this.type === SECTION_HANDLES.BANNER;
        },
        isStore() {
            return this.type === SECTION_HANDLES.STORE;
        },
        isJob() {
            return this.type === SECTION_HANDLES.JOB;
        },

        relatedJobCount() {
            if (this.isStore || this.isArticle) {
                const jobs = this.data.relatedJobs;
                return jobs.length > 0 ? jobs.length : false;
            } else {
                return false;
            }
        },
        relatedStores() {
            if (!this.isBanner) {
                return false;
            } else {
                const stores = this.data.relatedStores;
                return stores && stores.length > 0 ? stores.length : false;
            }
        },
        sideLabel() {
            if (this.isStore && this.relatedJobCount) {
                return `Poste${this.relatedJobCount > 1 ? "s" : ""} à combler`;
            }

            if (this.isArticle) {
                //return this.data.type === 'recruitment' ? 'Recrutement' : 'Nouvelles'
                return this.data.type == "recruitment"
                    ? `Poste${this.relatedJobCount > 1 ? "s" : ""} à combler`
                    : this.data.category;
            }

            if (this.isBanner) {
                return this.relatedStores <= 1 ? `magasin` : `magasins`;
            }

            return false;
        },

        link() {
            let path = false;

            if (this.isJob) {
                path = `${linkResolver(SECTION_HANDLES.JOBS)}/${this.data.slug}`;
            } else if (this.isStore) {
                path = `${linkResolver(SECTION_HANDLES.STORES)}/${this.data.slug}`;
            }

            return path;
        },
    },
    methods: {
        handleMouseEnter(event) {
            const parent = event.target.parentElement;
            parent.classList.add("is-hover");
        },
        handleMouseLeave(event) {
            const parent = event.target.parentElement;
            parent.classList.remove("is-hover");
        },
        jobSectionLink(store) {
            return store.region ? `${linkResolver(SECTION_HANDLES.JOBS)}#${store.region.slug}` : "";
        },
    },
};
</script>

<style lang="scss">
.c-card {
    --card-padding: 1em;
    --card-side-width: 2em;
    display: grid;
    grid-template-columns: 1fr var(--card-side-width);

    .c-card__asset {
        transform: translateZ(0);

        .o-asset {
            overflow: hidden;

            .o-asset__img {
                transition: transform 0.5s $out-quad;
            }
        }
    }

    .is-hover {
        .c-card__asset {
            .o-asset__img {
                transform: scale(1.05);
                transition: transform 1.75s $out-quad;
            }
        }
    }

    .c-card__buttons .c-btn {
        width: fit-content;
    }

    &.-black {
        .c-card__asset,
        .c-card__inner {
            background: $color-dark;
        }
        .c-card__asset,
        .c-card__content {
            * {
                color: #fff;
            }
        }
        .c-card__inner {
            border: 0;
        }
    }

    &.-article {
        --card-side-width: 1em;

        .c-card__link {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
        }

        .c-card__inner {
            padding-top: 0;
            padding-bottom: calc(var(--card-padding) * 3);
        }

        .c-card__asset {
            margin-top: 0;
            padding-top: calc(3 / 4 * 100%);

            picture {
                border-top-left-radius: calc(#{$border-radius-md} - 1px);
                border-top-right-radius: calc(#{$border-radius-md} - 1px);
                transform: translateZ(0);
            }
        }

        .c-card__asset-count {
            position: absolute;
            bottom: var(--card-padding);
            left: var(--card-padding);
        }

        .c-card__content {
            margin-top: var(--card-padding);
        }
        .c-card__title {
            margin-top: 0.5em;
            margin-bottom: 0.65em;
            text-transform: none;
        }
        .c-card__date {
            @extend .t-caps, .-primary;
            margin-top: 0.5em;
        }
        .c-card__buttons {
            left: auto;
            bottom: 0;
            right: 0;
            z-index: 1;

            .c-btn {
                --btn-padding-x: 1em;
                --btn-padding-y: 1.25em;

                &__inner {
                    border-radius: $border-radius-sm 0 0 0;
                }
            }
        }
    }

    &.-banner {
        --card-side-width: 3em;
        .c-card__inner {
            border: 0;
            padding-top: 0;
            background: $color-dark;
        }
        .c-card__asset {
            margin-top: 0;
            padding-top: calc(3 / 2 * 100%);
            z-index: 0;
            border-radius: $border-radius-md $border-radius-md 0 $border-radius-md;
            overflow: hidden;
            transform: translateZ(0);

            &:before {
                @include pseudo-el(
                    $height: 50%,
                    $bg: linear-gradient(to bottom, rgba($color-dark, 0.3), rgba($color-dark, 0))
                );
                position: absolute;
                top: 0;
                left: 0;
                z-index: 2;
            }
        }
        .c-card__content {
            position: absolute;
            top: calc(var(--card-padding) * 2);
            left: 0;
            width: 100%;
            padding: var(--card-padding);

            .c-card__logo {
                width: 100%;
                height: 3.25em;
                margin: auto;
                filter: invert(1);

                .o-asset__img {
                    object-fit: contain;
                }
            }
            .c-card__title {
                visibility: hidden;
            }
        }

        .c-card__side {
            padding-left: calc(var(--card-padding) / 2);
        }

        .c-card__side-count {
            margin-top: 0.3em;
            margin-bottom: -0.15em;
        }

        .c-card__side-label {
            html.is-chrome & {
                transform: translateX(0.125em) rotate(180deg);
            }
        }

        .c-card__buttons {
            align-items: center;
        }
    }

    &.-job,
    &.-store {
        .c-card__content {
            padding-bottom: 3em;
        }

        .c-card__asset {
            background-color: $color-secondary;
            border-top-right-radius: $border-radius-lg;
            border-bottom-left-radius: calc(#{$border-radius-md} - 4px);
            overflow: hidden;
        }

        .c-card__side-label {
            html.is-chrome & {
                transform: translateX(0.1em) rotate(180deg);
            }
        }

        .c-card__buttons {
            width: fit-content;
        }
    }

    &.-store {
        .c-card__content {
            height: 100%;
        }
        .c-card__title {
            margin-bottom: 0.2em;
        }

        .c-card__info {
            position: absolute;
            bottom: 0;
            z-index: 1;
            transform: translateY(50%);

            --info-padding-y: 0.5em;
            --info-padding-x: 0.5em;
        }

        .c-card__side-icon {
            --svg-width: 0.75em;

            display: flex;
            align-items: center;
            justify-content: center;
            width: 1.2em;
            height: 1.2em;
            background-color: $color-orange;
            border-radius: 50%;
        }

        .c-card__side-count {
            margin-top: 0.5em;
            margin-bottom: 0.5em;
        }
    }

    &.-job {
        --card-side-width: 1em;

        .c-card__title {
            margin-top: 0.2em;
            margin-bottom: 1em;
        }

        .c-card__buttons {
            .c-btn,
            .c-btn__inner {
                width: auto;
            }
        }
    }
}

.c-card__inner {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: var(--card-padding) var(--card-padding) 0;
    border: 1px solid $color-primary;
    border-radius: $border-radius-md $border-radius-md 0 $border-radius-md;
    overflow: hidden;
}

.c-card__asset {
    left: calc(-1 * var(--card-padding));
    display: block;
    width: calc(1px + 100% + var(--card-padding) * 2);
    height: 0;
    margin-top: auto;
    padding-top: calc(4 / 3 * 100%);

    .o-asset {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.c-card__side {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    width: var(--card-side-width);
    height: 100%;

    & > a {
        display: inline-flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: column;
    }
}

.c-card__side-label {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
}

.c-card__buttons {
    position: absolute;
    bottom: var(--card-padding);
    right: var(--card-padding);
    left: var(--card-padding);
    width: auto;
    display: flex;
    flex-direction: column;

    .c-btn {
        --btn-padding-x: 0.75em;
        --btn-padding-y: 0.75em;
        //width: 100%;

        &:not(:first-child) {
            margin-top: 0.5em;
        }

        .c-btn__inner {
            justify-content: center;
            width: 100%;
        }
    }
}
</style>
