<template>
    <tag
        :is="tag"
        :href="(tag == 'a' || tag == 'router-link') && href !== null ? href : false"
        :to="tag == 'router-link' && href !== null ? href : false"
        :title="href !== null ? (title ? title : label) : false"
        :target="tag == 'a' ? '_blank' : false"
        :rel="tag == 'a' ? 'noopener' : false"
        :class="className"
    >
        <span class="c-btn__inner">
            <icon v-if="iconBefore !== null" class="c-btn__icon" :icon="iconBefore" />
            <span v-if="label" class="c-btn__label">
                <slot>{{ label }}</slot>
            </span>
            <icon v-if="iconAfter !== null" class="c-btn__icon" :icon="iconAfter" />
        </span>
    </tag>
</template>

<script>
import Icon from "objects/Icon";

export default {
    name: "Btn",
    components: {
        Icon,
    },
    props: {
        tag: {
            type: String,
            default: "button",
        },
        href: {
            type: String,
            default: null,
        },
        title: {
            type: String,
            default: null,
        },
        label: {
            type: String,
            default: "Button Label",
        },
        iconBefore: {
            type: String,
            default: null,
        },
        iconAfter: {
            type: String,
            default: null,
        },
        modifier: {
            type: String,
            default: "primary",
        },
    },
    computed: {
        className() {
            let classname = "c-btn";

            if (this.modifier) {
                classname += ` -${this.modifier}`;
            }

            return classname;
        },
    },
};
</script>

<style lang="scss">
.c-btn {
    --btn-padding-y: 1em;
    --btn-padding-x: 1.5em;
    --btn-color: inherit;
    --btn-bg-color: transparent;
    --btn-border-color: #{$color-primary};

    display: inline-block;
    cursor: pointer;

    &.-primary {
        --btn-color: #{$color-light};
        --btn-bg-color: #{$color-primary};
    }

    &.-secondary {
        --btn-padding-x: 1em;
        --btn-padding-y: 0.6em;
        --btn-bg-color: #{$color-secondary};
    }

    &.-light {
        --btn-bg-color: #{$color-light};
        --btn-color: #{$color-dark};
    }

    &.-lighter {
        --btn-bg-color: #{$color-blue-lighter};
        --btn-color: #{$color-dark};
    }

    &.-blur {
        .c-btn__inner {
            @include blurred-bg($radius: inherit);
        }
    }

    &.-underline {
        --btn-padding-x: 0;
        --btn-padding-y: 0.5em;

        .c-btn__inner {
            &:before {
                top: auto;
                bottom: 0;
                height: 2px;
                background-color: var(--btn-border-color);
            }

            &:after {
                @include pseudo-el($height: 2px, $bg: $color-secondary);
                position: absolute;
                bottom: 0;
                left: 0;
                transform: scale(0, 1);
                transition: transform 0.8s $out-expo;
            }
        }

        &:hover {
            .c-btn__inner {
                &:after,
                &:before {
                    transform: scale(1.05, 1);
                }
            }
        }
    }

    &.-border {
        .c-btn__inner {
            &:before {
                border: 2px solid var(--btn-border-color);
            }
        }
    }

    &:hover {
        .c-btn__inner {
            &:before {
                transform: scale(1.05, 1.1);
            }
        }
    }
}

.c-btn__inner {
    z-index: 0;
    display: inline-grid;
    grid-auto-flow: column;
    gap: 1em;
    align-items: center;
    padding: var(--btn-padding-y) var(--btn-padding-x);
    color: var(--btn-color);
    border-radius: $border-radius-sm;

    &:before {
        @include pseudo-el;
        z-index: -1;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: var(--btn-bg-color);
        border-radius: inherit;
        transition: transform 0.8s $out-expo;
    }
}

.c-btn__icon {
    flex-shrink: 0;

    &.-arrow-down {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.25em;
        height: 1.25em;
        border-radius: 50%;
        background-color: $color-primary;
    }

    & + .c-btn__label {
        margin-left: 1em;
    }
}
</style>
