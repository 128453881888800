<template v-if="label">
    <a
        v-if="googleMap"
        :href="googleMap"
        :title="label"
        target="_blank"
        rel="noopener"
        class="o-address"
    >
        <address v-html="labelHTML"></address>
    </a>
    <address
        v-else
        v-html="labelHTML"
        class="o-address"
    ></address>
</template>

<script>

export default {
    name: 'AppAddress',
    props: {
        address: {
            type: Object|Array,
            default: null,
        },
    },
    computed: {
        data() {
            const address = this.address
            // If object, return object
            if (!Array.isArray(address)) {
                return address
            }

            // Return first instance if array
            return address.length > 0 ? address[0] : false
        },
        label() {
            return this.data && this.data.label !== '' ? this.data.label : false
        },
        labelHTML() {
            return this.label ? this.label.replace(/\n/g, '<br>') : false
        },
        googleMap() {
            return this.data && this.data.googleMap !== '' ? this.data.googleMap : false
        },
    },
};

</script>

<style lang="scss">

.o-address {
    display: block;
}

</style>
