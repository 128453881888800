<template>
    <footer class="l-footer">
        <deco field="tracks" />
        <div class="grid">
            <div class="l-footer__inner">
                <div class="l-footer__left">
                    <router-link
                        to="/"
                        title="Groupe Boucher"
                    >
                        <icon icon="logo-gb" />
                    </router-link>

                    <award class="l-footer__award" />
                </div>
                <div class="l-footer__middle">
                    <app-address
                        :address="general.address"
                        class="l-footer__address"
                    />
                    <div class="l-footer__links">
                        <a
                            v-if="general.facebook"
                            :href="general.facebook"
                            title="Page Facebook du Groupe Boucher"
                            target="_blank"
                            rel="noopener"
                            class="l-footer__social"
                        >
                            <icon icon="facebook" />
                        </a>
                        <a
                            v-if="general.linkedin"
                            :href="general.linkedin"
                            title="Page LinkedIn du Groupe Boucher"
                            target="_blank"
                            rel="noopener"
                            class="l-footer__social"
                        >
                            <icon icon="linkedin" />
                        </a>
                        <btn
                            v-if="general.phone"
                            tag="a"
                            :href="general.phone|tel"
                            title="Nous téléphoner"
                            :label="general.phone"
                            modifier="underline"
                            class="l-footer__phone"
                        />
                    </div>
                    <div class="l-footer__links">
                        <btn
                            tag="router-link"
                            :href="SECTION_HANDLES.CONTACT|resolver"
                            label="Nous joindre"
                            modifier="underline"
                        />

                        <app-select
                            v-if="privilegeLinks"
                            v-model="activePrivilegeLink"
                            ref="privilegeLinksInput"
                            name="privilegeLinks"
                            :options="privilegeLinks"
                            placeholder="Zone privilège"
                            as="title:privilege"
                            :opensUp="true"
                            @input="handleprivilegeLink"
                        />

                        <!-- <btn
                            label="Zone privilège"
                            modifier="secondary"
                            icon-after="arrow-down"
                        /> -->
                    </div>
                </div>
                <div class="l-footer__right">
                    <btn
                        tag="router-link"
                        :href="STATIC_ROUTES.PARTNERSHIP"
                        label="Demande de commandite"
                        icon-after="arrow-right"
                        modifier="border"
                        class="l-footer__btn-big"
                    />
                    <span class="l-footer__copy">&copy;Groupe Boucher inc. Tous droits réservés.</span>
                    <!-- <btn
                        tag="a"
                        href="https://mambomambo.ca/"
                        title="MamboMambo studio de design graphique et web à Québec"
                        label="MamboMambo"
                        class="l-footer__mambo"
                        modifier="underline"
                    /> -->
                </div>
            </div>
            <router-link
                v-if="jobs.length >= 5 && $route.meta.section !== SECTION_HANDLES.JOBS"
                :to="SECTION_HANDLES.JOBS|resolver"
                title="Recrutement"
                class="l-footer__cta"
            >
                <span class="t-t5">
                    {{ jobs.length }} postes à combler
                </span>
                <btn
                    tag="span"
                    label="Recrutement"
                    modifier=""
                    icon-after="arrow-right"
                />
            </router-link>
        </div>
    </footer>
</template>

<script>

import Icon from 'objects/Icon';
import Deco from 'objects/Deco';
import AppAddress from 'objects/AppAddress';
import AppSelect from 'objects/AppSelect';

import Btn from 'components/Btn';
import Award from 'components/Award';

import { SECTION_HANDLES, STATIC_ROUTES } from 'src/constants'
import { mapState } from 'vuex'

export default {
    name: 'AppFooter',
    components: {
        AppSelect,
        Icon,
        Deco,
        AppAddress,
        Award,
        Btn
    },
    data: () => ({
        SECTION_HANDLES,
        STATIC_ROUTES,
        activePrivilegeLink: null
    }),
    computed: {
        ...mapState({
            general: state => state.global.settings,
            jobs: state => state.jobs.elements,
            stores: state => state.stores.elements
        }),
        privilegeLinks() {
            return this.stores.filter(s => s.privilege !== null).map(store => {
                return {
                    title: store.titleAlt || store.title,
                    privilege: store.privilege
                }
            })
        }
    },
    methods: {
        handleprivilegeLink(url) {

            if (url) {

                var win = window.open(url, '_blank');
                win.focus();

                // Reset afterend
                setTimeout(() => {
                    this.activePrivilegeLink = null
                    this.$refs.privilegeLinksInput.reset()
                }, 500)
            }
        }
    },
};

</script>

<style lang="scss">

.l-footer {
    overflow: hidden;

    .o-deco {
        --deco-size: 15em;

        bottom: calc(var(--deco-size) * -.8);
        left: calc(100% - var(--deco-size)/2);

        @media #{md("xs", "max")} {
            left: calc(var(--deco-size) * -.3);
            bottom: calc(var(--deco-size) * -.6);
        }
    }

    @media #{md("xs")} {

        .o-deco {
            bottom: calc(var(--deco-size) * -.3);
        }
    }

    @media #{md("sm")} {

        .o-deco {
            display: none;
        }
    }

    @media #{md("md")} {

        .o-deco {
            bottom: calc(var(--deco-size) * -.8);
            left: calc(var(--deco-size) * -.3);
            display: block;
        }
    }
}

.l-footer__inner {
    display: grid;
    grid-row-gap: 2em;
    grid-column-gap: var(--grid-gutter);

    padding-top: 2em;
    padding-bottom: 6em;

    @media #{md("sm")} {
        grid-template-columns: 1fr 1fr;
    }

    @media #{md("md")} {
        grid-template-columns: 2fr 4fr 4fr;
        padding-top: 3em;
        padding-bottom: 3em;
    }

    @media #{md("lg")} {
        grid-template-columns: 3fr 5fr 4fr;
    }
}

.l-footer__cta {
    padding: var(--grid-gutter);
    color: $color-light;
    background-color: $color-dark;
    border-top-left-radius: #{$border-radius-sm};
    border-top-right-radius: #{$border-radius-sm};
    overflow: hidden;

    &:before {
        @include pseudo-el($bg: $color-primary);
        position: absolute;
        top: 0;
        left: 0;
        transform: scale(1, 0);
        transform-origin: 50% 100%;
        transition: transform .8s $out-expo;
    }

    .c-btn {
        --btn-padding-x: 0;
        --btn-padding-y: 0;
    }

    &:hover {

        &:before {
            transform: scale(1);
        }
    }

    @media #{md("xs", "max")} {
        display: grid;
        grid-gap: .5em;
        max-width: 60vw;
        padding: var(--grid-gutter) 2em;
        margin: 0 auto;
    }

    @media #{md("xs")} {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    @media #{md("md")} {
        width: calc(5/6 * 100%);
        margin-right: auto;
        margin-left: auto;
        padding: 1em 2em;
    }
}

.l-footer__left {

    a {
        display: inline-block;
    }

    @media #{md("sm")} {
        grid-column-start: 1;
        grid-column-end: 3;
    }

    @media #{md("md")} {
        grid-column-start: 1;
        grid-column-end: 2;
    }

    .l-footer__award {
        margin: var(--grid-gutter) 0;
    }
}

.l-footer__social {
    display: inline-flex;
    align-items: center;
    color: $color-primary;

    @media #{md("xs", "max")} {
        width: $accessible-size;
        height: $accessible-size;
        justify-content: center;
    }
}

.l-footer__phone {
    @media #{md("xs", "max")} {
        order: -1;
        margin-left: 0 !important;
        margin-right: 1em;
    }
}

.l-footer__address {
    display: inline-block;
}

.l-footer__links {
    margin-top: 1.5em;
    display: flex;
    align-items: center;

    > *:not(:first-child) {
        margin-left: 1em;
    }

    .o-select {
        --input-height: 2.75em;

        --input-border-color: #{$color-secondary};
        --input-border-radius: #{$border-radius-sm};

        --input-color-text: #{$color-dark};
        --input-color-bg: #{$color-secondary};
        --input-option-color-bg: #{$color-light};

        --input-disabled-color-text: #{$color-light};
        --input-disabled-color-bg: #{$color-primary};

        --input-checked-color-text: #{$color-light};
        --input-checked-color-bg: #{$color-primary};
    }
}

.l-footer__right {

    @media #{md("sm")} {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
}

.l-footer__btn-big {
    --btn-padding-y: 2em;
    --btn-padding-x: 2em;

    @media #{md("xs", "max")} {
        --btn-padding-x: min(2em, 4vw);
        display: block;
        width: 100%;

        .c-btn__inner {
            width:100%;
            justify-content: center;
        }
    }
}

.l-footer__copy {
    display: block;
    margin-top: 2rem;
    font-size: .75em;
    text-transform: uppercase;

    @media #{md("sm")} {
        margin-top: auto;
    }

    @media #{md("xs", "max")}  {
        font-size: min(0.75em, 3vw);
    }
}

.l-footer__mambo {}

</style>
