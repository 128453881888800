<template>
    <div
        v-if="hours && hours.length > 0"
        class="o-opening-hours"
    >
        <h3
            v-if="title"
            class="o-opening-hours__title | t-t5-bold"
        >
            {{ title }}
        </h3>
        <table class="o-opening-hours__table">
            <tr
                v-for="(item, i) in hours"
                :key="`opening-hours-${i}-${item.day}`"
                itemprop="openingHours"
                :content="item.day + ' ' + item.hours"
            >
                <td>{{ item.day }}</td>
                <td>{{ item.hours }}</td>
            </tr>
        </table>
    </div>
</template>

<script>

export default {
    name: 'OpeningHours',
    props: {
        hours: {
            type: Array,
            default: () => [],
        },
        title: {
            type: String,
            default: 'Heures d’ouverture',
        },
    },
};

</script>

<style lang="scss">

.o-opening-hours {}

.o-opening-hours__title {
    margin-bottom: 1rem;
}

.o-opening-hours__table {
    width: 100%;
    max-width: 24em;

    td {

        &:nth-child(1) {
            padding-right: 1em;
        }

        &:nth-child(2) {
            white-space: nowrap;
        }
    }
}

</style>
