<template>
    <div class="m-store">
        <div
            v-if="data.cover"
            class="l-modal__cover"
        >
            <span
                v-if="data.region"
                class="l-modal__label"
            >
                {{ data.region.title }}
            </span>
            <asset
                :asset="data.cover"
                :radius="true"
            />
        </div>
        <grid-content class="l-modal__content | m-store__content">
            <template #left>
                <div class="t-content">
                    <banners-list :displayLogo="true" :items="logos" />

                    <h2 class="m-store__name | t-t3">{{ data.title }}</h2>

                    <div class="m-store__buttons">
                        <btn
                            tag="a"
                            :label="privilege.label"
                            :href="privilege.link"
                            icon-after="arrow-right"
                        />
                        <div class="m-store__privileges">
                            <div v-html="privilege.text"></div>
                        </div>
                    </div>
                </div>
            </template>
            <template #right>
                <div class="c-list">
                    <div class="c-list__item | t-content">
                        <h3 class="t-t5-bold">Coordonnées</h3>
                        <app-address :address="data.address" />

                        <div class="m-store__links">
                            <a
                                v-if="data.facebook"
                                :href="data.facebook"
                                title="Page Facebook"
                                target="_blank"
                                rel="noopener"
                                class="m-store__social-account"
                            >
                                <icon icon="facebook" />
                                <span>Facebook</span>
                            </a>
                            <a
                                v-if="data.instagram"
                                :href="data.instagram"
                                title="Page Instagram"
                                target="_blank"
                                rel="noopener"
                                class="m-store__social-account"
                            >
                                <icon icon="instagram" />
                                <span>Instagram</span>
                            </a>
                            <btn
                                v-if="data.phone"
                                tag="a"
                                :href="data.phone|tel"
                                title="Appeler au magasin"
                                :label="data.phone"
                                modifier="underline"
                            />
                        </div>

                        <br>

                        <div class="u-dp-flex u-fd-column | t-content">
                            <h4 class="t-caps">{{ data.role ? data.role : 'Gérant' }}</h4>
                            <p>
                                {{ data.manager }}
                                <btn
                                    v-if="data.email"
                                    tag="a"
                                    :href="`mailto:${data.email}`"
                                    title="Envoyer un courriel"
                                    :label="data.email"
                                    modifier="underline"
                                />
                            </p>
                        </div>
                    </div>
                    <div class="c-list__item | t-content">
                        <opening-hours
                            v-if="!data.hideOpeningHours"
                            :hours="data.openingHours"
                        />

                        <info
                            v-if="data.notes"
                            :content="data.notes"
                        />

                        <icon
                            icon="logo-gb-long"
                            class="m-store__logo"
                        />
                    </div>
                </div>
            </template>
        </grid-content>

        <push
            v-if="push && push.count > 1"
            :title="push.title"
            btnLabel="Découvrir les magasins"
            :btnLink="push.path"
            :asset="push.image"
        />
    </div>
</template>

<script>

import GridContent from 'layout/GridContent';
import Icon from 'objects/Icon';
import Asset from 'objects/Asset';
import AppAddress from 'objects/AppAddress';
import OpeningHours from 'objects/OpeningHours';
import BannersList from 'objects/BannersList';
import Btn from 'components/Btn';
import Info from 'components/Info';
import Push from 'components/Push';

import { SECTION_HANDLES } from 'src/constants'
import { linkResolver } from 'src/router'

import { mapState } from 'vuex'

export default {
    name: 'StoreModal',
    components: {
        GridContent,
        Icon,
        Asset,
        AppAddress,
        OpeningHours,
        BannersList,
        Btn,
        Info,
        Push,
    },
    props: {
        data: Object
    },
    computed: {
        ...mapState({
            stores: state => state.stores.elements,
            settings: state => state.global.settings
        }),

        logos() {

            if (this.data.overrideBanners && this.data.logos.length) {
                let _logos = []

                this.data.logos.forEach(logo => {
                    _logos.push({
                        logo
                    })
                })

                return _logos
            }

            return this.data.banners
        },
        privilege() {

            const data = this.data,
                  overridePrivilege = data.overridePrivilege

            return {
                link: data.privilege,
                label: (overridePrivilege && data.privilegeLabel) ? data.privilegeLabel : 'Zone privilège',
                text: (overridePrivilege && data.privilegeText) ? data.privilegeText : this.settings.privilegeZone
            }
        },
        push() {
            if(!this.data.region) {
                return false
            }

            const storesRegionIds = this.stores.map(s => s.regionID)
            const storesCount = storesRegionIds.filter(id => id === this.data.region.id).length
            const label = (storesCount - 1) > 1 ? `autres magasins` : `autre magasin`

            return {
                count: storesCount,
                title: `${this.data.region.title}<br> ${storesCount - 1} ${label}`,
                path: `${linkResolver(SECTION_HANDLES.STORES)}#${this.data.region.id}`,
                image: this.data.region.image,
            }
        },

        listData() {
            return [
                {
                    address: this.data.address,
                    phone: this.data.phone,
                    role: this.data.role,
                    manager: this.data.manager,
                    email: this.data.email
                },
                {
                    hideOpeningHours: this.data.hideOpeningHours,
                    openingHours: this.data.openingHours,
                    notes: this.data.notes
                }
            ]
        }
    },
};

</script>

<style lang="scss">

.m-store__cover {
    margin-bottom: vh(10);

    .o-asset {
        width: calc(100% - 2em);
        margin-left: auto;
    }

    @media #{md("md")} {

        .o-asset {
            width: calc(100% - 3em);
        }
    }
}

.m-store__region {
    position: absolute;
    top: 0;
    left: 0;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
}

.m-store__content {
    --list-cols-xs: 1;
    --list-cols-sm: 2;
    --list-cols-md: 2;

    @media #{md("sm")} {

        .c-list__item {
            display: flex;
            flex-direction: column;
        }
    }
}

.m-store__name {
    margin-bottom: 2em;
}

.m-store__buttons {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    .c-btn {
        display: flex;
        flex-basis: 100%;
        margin-right: 1em;
        margin-bottom: 1em;

        @media #{md("sm")} {
            flex-basis: auto;
            margin-bottom: 0;
        }
    }
}

.m-store__privileges {
    display: inline-block;
    margin-top: 1em;

    @media #{md("sm")} {
        display: block;
        width: 100%;
        padding-right: var(--grid-gutter);
    }
    .o-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.25em;
        height: 1.25em;
        background-color: $color-secondary;
        border-radius: 50%;
        margin-bottom: .5em;
    }
}

.m-store__links {
    display: flex;
    align-items: center;

    @media #{md("md", "max")} {
        flex-wrap: wrap;
    }
    > *:not(:first-child) {
        margin-left: 0.5em;
    }
    > *:last-child {
        margin-left: 1em;

        @media #{md("md", "max")} {
            width: 100%;
            order: -1;
            margin-left: 0;
            margin-bottom: 1em;
        }
    }

}

.m-store__social-account {
    --ux-padding: .5em;

    display: flex;
    align-items: center;
    padding: var(--ux-padding);

    &:first-of-type {
        margin-left: calc(var(--ux-padding) * -1);
    }

    // UX
    @media (pointer: coarse) {
        --ux-padding: .9em;
    }

    .o-icon {
        color: $color-primary;
    }

    span {
        margin-left: .5em;

        @media #{md("md")} {
            display: none;
            visibility: hidden;
        }
    }
}

.m-store__logo {

    @media #{md("sm")} {
        margin-top: auto !important;
        margin-bottom: .5em;
    }
}

/*=====  End of Opening hours table  ======*/


</style>
