<template>
    <div :class="className">
        <input
            ref="input"
            :id="`input-file-${_uid}`"
            class="o-input-file__input"
            @change="inputChange"
            type="file"
            name="attachement"
            @focus="isActive = true"
            @blur="isActive = false"
        />
        <label
            :for="`input-file-${_uid}`"
            class="o-input-file__label"
        >
            <span class="o-input-file__text">
                {{ required ? `${this.placeholder}*` : this.placeholder }}
            </span>

            <btn
                tag="span"
                modifier="secondary"
                icon-after="arrow-down"
                :label="this.value ? 'Remplacer' : 'Sélectionner un fichier'"
            />
        </label>
    </div>
</template>

<script>

import Btn from 'components/Btn';
import Icon from 'objects/Icon';

export default {
    name: 'AppInputFile',
    components: {
        Btn,
        Icon,
    },
    props: {
        label: {
            type: String,
            required: true,
        },
        required: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        value: '',
        placeholder: '',
        isActive: false,
        isFilled: false,
    }),
    mounted() {
        this.placeholder = this.label
    },
    computed: {
        className() {
            let className = 'o-input-file t-small'

            if(this.isActive || this.isFilled) {
                className += ' is-filled'
            }

            return className
        },
    },
    methods: {
        inputChange(e) {

            const $target = e.target

            const filename = $target.value.split( '\\' ).pop()

            if (filename) {
                this.placeholder = filename
                this.value = $target.files[0]
            } else {
                this.placeholder = this.label
                this.value = ''
            }

            this.$emit('input', this.value)
        },
        reset() {
            this.value = ''
        },
    },
    watch: {
        value(val) {
            this.$emit('input', val)
            if (val !== '') {
                this.isFilled = true
            } else {
                this.isFilled = false
            }
        },
    }
};

</script>

<style lang="scss">

.o-input-file {
    color: var(--input-color-text);
}

.o-input-file__input {
    display: none;
}

.o-input-file__label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    // height: var(--input-height);

    .c-btn {
        flex-grow: 0;
        flex-shrink: 0;
        height: var(--height);
        margin-left: .8em;
    }

    &:hover {

        .c-btn {
            color: var(--color-bg);
            border-radius: 0;
        }

        .c-btn__bg:after {
            transform: translate(0, calc(100% - .15em));
        }
    }

    @media #{md("sm", "max")} {
        flex-wrap: wrap;
    }
}

.o-input-file__text {
    display: flex;
    align-items: center;
    width: 100%;
    height: var(--input-height);
    padding-right: .8em;
    padding-left: .8em;
}

</style>
