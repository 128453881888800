<template>
    <div
        :class="className"
        v-reveal.once="reveal ? revealHandle : false"
        :style="cellsHeight ? `--slide-height: ${cellsHeight}px` : false"
    >
        <flickity
            ref="flkty"
            :options="opts"
            class="c-slider__inner"
        >
            <div
                v-if="fullwidth"
                class="c-slider__spacer"
            ></div>
            <div
                v-for="(slide, i) in slides"
                :key="`slider-${_uid}-slide-${i}`"
                ref="slide"
                class="c-slider__slide"
            >
                <slot name="slide" v-bind="{...slide, index: i, isActive: i === currentIndex, delta: currentIndex - oldIndex}">
                    {{ slide }}
                </slot>
            </div>
            <div
                v-if="fullwidth"
                class="c-slider__spacer"
            ></div>
        </flickity>

        <nav
            v-if="prevNext"
            class="c-slider__nav"
            :style="`--slider-progress: ${sliderProgress};`"
        >
            <div
                v-if="tabs"
                class="c-slider__tabs"
            >
                <button
                    v-for="(slide, i) in slides"
                    :key="`slider-${_uid}-tab-${i}`"
                    class="c-slider__tab"
                    :class="{ 'is-active' : i === currentIndex}"
                    @click="select(i)"
                >
                    {{ slide.title }}
                </button>
            </div>
            <button
                aria-label="Précédent"
                class="c-slider__btn -prev | u-as"
                :class="{ 'is-disabled' : currentIndex === 0 }"
                @click="previous()"
            >
                <icon icon="arrow-left" />
            </button>
            <button
                aria-label="Suivant"
                class="c-slider__btn -next | u-as"
                :class="{ 'is-disabled' : sliderProgress === 1 }"
                @click="next()"
            >
                <icon icon="arrow-right" />
            </button>
        </nav>
    </div>
</template>

<script>

import Icon from 'objects/Icon'
import AnimText from 'objects/AnimText'

import Flickity from 'vue-flickity'
import Variables from 'src/mixins/variables';

export default {
    name: 'Slider',
    components: {
        Icon,
        AnimText,
        Flickity,
    },
    mixins: [ Variables ],
    data: () => ({
        windowWidth: 0,
        isDragging: false,
        currentIndex: -1,
        oldIndex: -1,
        flktySlides: 0,
        opts: {
            pageDots: false,
            prevNextButtons: false,
            imagesLoaded: true,
            contain: true,
        },
        cellsHeight: false,
    }),
    props: {
        modifier: {
            type: String,
            default: null,
        },
        prevNext: {
            type: Boolean,
            default: true,
        },
        translate: {
            type: Boolean,
            default: true,
        },
        tabs: {
            type: Boolean,
            default: false,
        },
        options: {
            type: Object,
            default: () => {},
        },
        slides: {
            type: Array,
            default: () => [],
        },
        reveal: {
            type: Boolean,
            default: true,
        },
        fullwidth: {
            type: Boolean,
            default: false,
        },
        fullheight: {
            type: Boolean,
            default: false,
        },
        direction: {
            type: String,
            default: 'horizontal',
        },
    },
    created() {

        this.windowWidth = this.W.w

        if(!this.translate) {
            this.opts.draggable = false
        }

        this.opts = {...this.opts, ...this.options}
    },
    mounted() {
        const flkty = this.$refs.flkty

        flkty.on('dragStart', this.onDragStart = () => this.isDragging = true );
        flkty.on('dragEnd', this.onDragEnd = () => this.isDragging = false );

        this.flktySlides = flkty.slides().length

        flkty.on('resize', this.resize);

        flkty.on('select', this.onSelect = i => {
            this.oldIndex = this.currentIndex
            this.currentIndex = i
            this.$emit('select', this.currentIndex, this.oldIndex)
        })

        this.setSlidesHeight()
    },
    computed: {
        className() {
            let classname = 'c-slider'

            if(this.modifier) {
                classname += ` -${this.modifier}`
            }

            if(!this.translate) {
                classname += ` -no-translate`
            }

            if(this.direction) {
                classname += ` -${this.direction}`
            }

            if(this.tabs) {
                classname += ` -tabs`
            }

            if(this.isDragging) {
                classname += ` is-dragging`
            }

            if(this.fullwidth) {
                classname += ` -fullwidth`
            }

            if(this.cellsHeight) {
                classname += ` -fullheight`
            }

            return classname
        },
        totalSlides() {
            return this.slides.length
        },
        sliderProgress() {
            const progress = this.currentIndex/(this.flktySlides - 1)
            return progress
        },
    },
    methods: {
        select(index) {
            this.$refs.flkty.select(index)
        },
        resize() {

            //const flkty = this.$refs.flkty

            setTimeout(() => {

                // Check if its a real resize
                if (this.windowWidth != this.W.w) {

                    this.windowWidth = this.W.w
                    this.flktySlides = this.totalSlides//flkty.slides().length
                    this.setSlidesHeight()
                }
            }, 75)
        },
        previous() {
            this.$refs.flkty.previous()
        },
        next() {
            this.$refs.flkty.next()
        },
        revealHandle(state) {
            if(state.isActive) {
                this.currentIndex = 0
            }
        },
        setSlidesHeight() {

            if (!this.fullheight)
                return

            if (this.translate)
                return

            this.cellsHeight = false

            this.$nextTick(() => {
                let newHeight = 0
                this.$refs.slide.forEach(slide => {
                    const slideHeight = slide.offsetHeight

                    if(slideHeight > newHeight) {
                        newHeight = slideHeight
                    }
                })
                this.cellsHeight = newHeight
            })
        }
    },
    beforeDestroy() {
        const flkty = this.$refs.flkty

        flkty.off('dragStart', this.onDragStart);
        flkty.off('dragEnd', this.onDragEnd);
        flkty.off('resize', this.resize);
        flkty.off('select', this.onSelect);
    }
};

</script>

<style lang="scss">


.c-slider {
    --slide-width: 100%;

    .flickity-viewport {
        transition: height 0.2s;
    }

    &.-no-translate {
        --slide-width: 100%;

        .flickity-slider {
            transform: none !important;
        }

        .c-slider__slide {

            &:not(:first-child) {
                top: 0;
                left: 0 !important;
                width: 100%;
                // height: 100%;
            }

            &.is-selected {
                z-index: 1;
            }
        }
    }

    &.-fullwidth {

        .flickity-viewport {
            width: 100vw;
            left: calc(50% - 50vw);
        }

        .flickity-slider {
            // margin-left: var(--grid-start-x);
            // width: calc(100% + 2 * var(--grid-start-x));
            // width: $grid-width;
            // max-width: $grid-max-width;
        }

        .c-slider__slide {

            &:first-child {
                // padding-left: var(--grid-start-x);
            }
        }

        .c-slider__spacer {
            width: var(--grid-start-x);
        }
    }

    &.-fullheight {

        .flickity-viewport {
            height: var(--slide-height) !important;
        }

        .c-slider__slide {
            height: var(--slide-height);
        }
    }

    @media #{md("md")} {
        &.-vertical {
            display: grid !important;
            grid-template-columns: 1fr 2.75rem !important;

            .c-slider__nav {
                margin-top: 0;
                flex-direction: column;
                justify-content: flex-start;

                .c-slider__btn {
                    --slider-btn-width: 2.75rem;
                    transform: rotate(90deg);
                    transform-origin: center;
                    margin-bottom: 0.5em;
                }
            }
        }
    }

    &.is-dragging {

        a,
        button {
            pointer-events: none;
        }
    }

    @media #{md("sm")} {
        --slide-width: calc(1/3 * 100%);

        &.-tabs {
            display: flex;
            flex-direction: column-reverse;

            .c-slider__nav {
                margin-bottom: 2em;

                &:before {
                    top: auto;
                    bottom: 0;
                }

                &:after {
                    display: none;
                }
            }
        }
    }

    @media #{md("md")} {
        --slide-width: calc(1/4 * 100%);
    }

    @media #{md("xl")} {
        --slide-width: calc(1/5 * 100%);
    }
}

.c-slider__slide {
    display: block;
    width: var(--slide-width);
}

.c-slider__nav {
    --slider-progress: 0;

    display: flex;
    justify-content: flex-end;
    margin-top: 2em;
    overflow: hidden;

    &:after,
    &:before {
        position: absolute;
        left: 0;
    }

    &:before {
        @include pseudo-el($width: calc(100% - 3.5em * 2 - 3em), $height: 1px, $bg: $color-secondary);
        top: calc(50% - 1px/2);
    }

    &:after {
        @include pseudo-el($width: calc(100% - 3.5em * 2 - 3em), $height:3px, $bg: $color-light);
        top: calc(50% - 3px/2);
        transform: scale(var(--slider-progress), 1);
        transform-origin: 0 50%;
        transition: transform 1s $in-out-quint;
    }

    &.is-disabled {
        pointer-events: none;
    }
}

.c-slider__tabs {
    display: flex;
    margin-right: auto;

    @media #{md("sm", "max")} {
        display: none;
    }
}

.c-slider__tab {
    display: block;
    cursor: pointer;

    &:after {
        @include pseudo-el($height: 3px, $bg: currentColor);
        position: absolute;
        bottom: 0;
        left: 0;
        transform: scale(1, 0);
        transform-origin: 50% 100%;
        transition: transform .2s $out-quad;
    }

    &.is-active {

        &:after {
            transform: scale(1);
        }
    }

    &:not(:first-child) {
        margin-left: 2em;
    }

    @media #{md("md", "max")} {
        font-size: .9em;
    }
}

.c-slider__btn {
    --slider-btn-width: 3.75rem;
    display: block;
    width: var(--slider-btn-width);
    height: var(--slider-btn-width);
    flex-grow: 0;
    flex-shrink: 0;
    // font-size: 2em;
    cursor: pointer;

    .o-icon {
        display: flex;
        place-content: center;
    }

    &:before {
        @include pseudo-el;
        position: absolute;
        top: 0;
        left: 0;
        border: 1px solid currentColor;
        border-radius: 50%;
        transform: scale(.9);
        transition: transform .2s ease-out;
    }

    &:hover:before {
        transform: scale(1);
        transition: transform .3s ease-out;
    }

    &.is-disabled {
        opacity: .5;
        pointer-events: none;
    }

    &.-prev {
        margin-right: 1.5em;
        transform-origin: 0 50%;
    }

    &.-next {
        transform-origin: 100% 50%;
    }
}

</style>
