<template>
    <div>
        <hero :title="page.title" :image="page.image" display="image-top">
            <template #before-image>
                <deco class="p-contact__deco" field="tracks" />
            </template>
        </hero>

        <app-section>
            <grid-content class="p-contact__content">
                <template #left>
                    <div class="t-content">
                        <h2 class="t-t3">Coordonnées</h2>

                        <app-address :address="general.address" />

                        <div class="p-contact__links | u-dp-flex">
                            <a
                                v-if="general.facebook"
                                :href="general.facebook"
                                title="Page Facebook du Groupe Boucher"
                                target="_blank"
                                rel="noopener"
                                class="p-contact__social"
                            >
                                <icon icon="facebook" />
                            </a>
                            <a
                                v-if="general.linkedin"
                                :href="general.linkedin"
                                title="Page LinkedIn du Groupe Boucher"
                                target="_blank"
                                rel="noopener"
                                class="p-contact__social"
                            >
                                <icon icon="linkedin" />
                            </a>
                            <btn
                                v-if="general.phone"
                                tag="a"
                                :href="general.phone | tel"
                                title="Nous téléphoner"
                                modifier="underline"
                                :label="general.phone"
                                class="p-contact__phone"
                            />
                        </div>
                    </div>
                    <opening-hours :hours="page.openingHours" />
                </template>
                <template #right>
                    <div class="c-list">
                        <div class="c-list__item">
                            <div class="t-cms" v-html="page.left"></div>
                        </div>
                        <div class="c-list__item">
                            <div class="t-cms" v-html="page.right"></div>
                        </div>
                    </div>
                </template>
            </grid-content>

            <app-form
                v-if="forms"
                formName="Contact"
                formTemplate="contact"
                :toEmail="forms.contactRecipient"
                subject="Demande de contact"
                :inputs="inputs"
                submitLabel="Soumettre"
                class="p-contact__form"
            >
                <template #success>
                    <div class="t-cms" v-html="forms.contactConfirmation" />
                </template>
            </app-form>
        </app-section>
    </div>
</template>

<script>
import Hero from "layout/Hero";
import AppSection from "layout/AppSection";
import GridContent from "layout/GridContent";
import Icon from "objects/Icon";
import Deco from "objects/Deco";
import AppAddress from "objects/AppAddress";
import OpeningHours from "objects/OpeningHours";
import Btn from "components/Btn";
import AppForm from "components/AppForm";

import { mapState } from "vuex";

export default {
    name: "Contact",
    components: {
        Hero,
        AppSection,
        GridContent,
        Icon,
        Deco,
        AppAddress,
        OpeningHours,
        Btn,
        AppForm
    },
    props: {
        page: Object
    },
    metaInfo() {
        return {
            title: this.page.seo ? this.page.seo.title : null
        };
    },
    data: () => ({
        inputs: [
            {
                title: "Contactez-nous"
            },
            {
                label: "Prénom",
                name: "firstName",
                type: "text",
                required: true,
                autocomplete: "given-name",
                half: true
            },
            {
                label: "Nom",
                name: "lastName",
                type: "text",
                required: true,
                autocomplete: "family-name",
                half: true
            },
            {
                label: "Courriel",
                name: "fromEmail",
                type: "email",
                required: true,
                autocomplete: "email",
                half: true
            },
            {
                label: "Téléphone",
                name: "phone",
                type: "tel",
                required: true,
                autocomplete: "tel",
                half: true
            },
            {
                label: "Message",
                name: "body",
                type: "textarea",
                required: true
            }
        ]
    }),
    computed: {
        ...mapState({
            general: state => state.global.settings,
            forms: state => state.global.forms
        })
    }
};
</script>

<style lang="scss">
.p-contact {
}

.p-contact__deco {
    --deco-size: 28vw;
    --deco-color: #{$color-orange};

    right: 2em;
    bottom: calc(-1 * var(--grid-start-x));

    @media #{md("lg")} {
        --deco-size: 22em;
        bottom: vh(-10);
    }
}

.p-contact__content {
    --list-cols-xs: 1;
    --list-cols-sm: 2;
    --list-cols-md: 1;
    --list-cols-lg: 2;
    --grid-gutter: 3em;

    .o-opening-hours {
        margin-top: 3em;
        padding-top: 1em;
        padding-bottom: 1em;
        border-top: $border-secondary;
    }

    @media #{md("md", "max")} {
        grid-template-columns: 1fr;
    }

    @media #{md("sm")} {
        --grid-col-left: 1fr;
        --grid-col-right: 1fr;
    }

    @media #{md("md")} {
        --grid-col-left: 4fr;
        --grid-col-right: 8fr;
    }
}

.p-contact__links {
    > *:not(:first-child) {
        margin-left: 1em;
    }
}

.p-contact__social {
    display: flex;
    align-items: center;
    color: $color-primary;

    @media #{md("xs", "max")} {
        width: $accessible-size;
        height: $accessible-size;
        justify-content: center;
    }
}

.p-contact__phone {
    @media #{md("xs", "max")} {
        order: -1;
        margin-left: 0 !important;
        margin-right: 1em;
    }
}

.p-contact__form {
    margin-top: vh(10);

    html.is-mobile-safari & {
        margin-top: 4.5rem;
    }

    @media #{md("xs")} {
    }

    @media #{md("sm")} {
        width: calc(3 / 4 * 100%);
        margin-left: auto;
        margin-right: auto;
    }

    @media #{md("md")} {
        width: calc(2 / 3 * 100%);
        margin-right: 0;
        padding-left: var(--grid-gutter-half);
    }

    @media #{md("lg")} {
        margin-right: auto;
        padding-left: 0;
    }
}
</style>
