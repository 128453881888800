/*
 ** Data parser
 */

import { SECTION_HANDLES } from "src/constants";
import store from "src/store";

const arrayHasItem = (array) => {
    return typeof array !== "undefined" && array.length > 0;
};

const parseData = (type, data) => {
    // console.log('parseData', type)

    switch (type) {
        case SECTION_HANDLES.HOME:
            // Implication image
            data.implicationImage = data.implicationImage.image;

            // Partners
            data.partners.forEach((p) => {
                if (arrayHasItem(p.type)) {
                    p.categoryID = p.type[0].id;
                }
            });

            // Articles
            data.articles.forEach((a) => {
                if (arrayHasItem(a.category)) {
                    a.category = a.category[0] ? a.category[0].title : null;
                } else {
                    a.category = null;
                }

                // Date
                if (arrayHasItem(a.datePost)) {
                    const postDate = new Date(a.datePost);
                    a.date = postDate.toLocaleDateString("fr-FR", { day: "numeric", month: "long", year: "numeric" });
                    a.year = postDate.getFullYear();
                }
            });

            break;
        case SECTION_HANDLES.STORE:
            // cover: [{}] => {}
            data.cover = arrayHasItem(data.cover) ? data.cover[0] : null;

            // regionID: [{ id: 1 }] => regionID: { id: 1 }
            data.regionID = arrayHasItem(data.region) ? data.region[0].id : null;
            data.region = data.regionID !== null ? store.getters["global/getRegionById"](data.regionID) : null;

            // banners: [{ id: 1 }, { id: 2 }] => bannersIDS: [1, 2]
            data.bannersIDS = arrayHasItem(data.banners) ? data.banners.map((b) => b.id) : [];
            data.banners = arrayHasItem(data.bannersIDS)
                ? store.getters["global/getBannersByIds"](data.bannersIDS)
                : [];

            // relatedJobs: [{ id: 1 }, { id: 2 }] => relatedJobsIDS: [1, 2]
            data.relatedJobsIDS = arrayHasItem(data.relatedJobs) ? data.relatedJobs.map((j) => j.id) : [];

            break;
        case SECTION_HANDLES.JOB:
            // cover: [{}] => {}
            data.cover = arrayHasItem(data.cover) ? data.cover[0] : null;
            data.department = arrayHasItem(data.department) ? data.department[0] : null;

            // store: [{ id: 1 }] => storeID: 1
            data.storeID = arrayHasItem(data.store) ? data.store[0].id : null;
            data.store = data.storeID !== null ? store.getters["stores/getElementById"](data.storeID) : null;

            break;
        case SECTION_HANDLES.IMPLICATIONS:
            // Partners
            data.partners.forEach((p) => {
                if (arrayHasItem(p.type)) {
                    p.type = p.type[0].id;
                }
            });

            break;
        case SECTION_HANDLES.ARTICLE:
            // Articles
            if (data.type === "news") {
                data.category = data.category[0] ? data.category[0].title : null;
            }

            // Date
            if (data.datePost) {
                const postDate = new Date(data.datePost);
                data.date = postDate.toLocaleDateString("fr-FR", { day: "numeric", month: "long", year: "numeric" });
                data.year = postDate.getFullYear();
            }

            break;

        case SECTION_HANDLES.ABOUT:
            // About
            data.about = arrayHasItem(data.about) ? data.about[0] : null;
            if (data.about !== null) {
                data.about.gallery.forEach((item) => {
                    if (arrayHasItem(item.store)) {
                        item.store = item.store[0].title;
                    }
                });
            }

            // Director
            data.director = arrayHasItem(data.director) ? data.director[0] : null;

            // File
            data.directorFile = data.director && arrayHasItem(data.director.file) ? data.director.file[0] : null;
    }

    return data;
};

export default parseData;
