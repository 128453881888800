<template>
    <div class="m-partnership">
        <anim-text text="Demande de commandite" class="m-partnership__title | t-t2" />
        <app-form
            v-if="forms"
            formName="Commandite"
            formTemplate="partnership"
            :toEmail="forms.sponsorShipRecipient"
            subject="Réception d’une nouvelle demande de commandite"
            :inputs="inputs"
            class="m-partnership__form"
            :data-test="forms.sponsorShipConfirmation"
        >
            <template #success>
                <div class="t-cms" v-html="forms.sponsorShipConfirmation" />
            </template>
        </app-form>
    </div>
</template>

<script>
import AnimText from "objects/AnimText";
import AppForm from "components/AppForm";

import { mapState } from "vuex";

export default {
    name: "PartnershipModal",
    components: {
        AnimText,
        AppForm
    },
    data() {
        return {
            inputs: [
                {
                    title: "Informations"
                },
                {
                    label: "Prénom",
                    name: "firstName",
                    type: "text",
                    required: true,
                    autocomplete: "given-name",
                    half: true
                },
                {
                    label: "Nom",
                    name: "lastName",
                    type: "text",
                    required: true,
                    autocomplete: "family-name",
                    half: true
                },
                {
                    label: "Courriel",
                    name: "fromEmail",
                    type: "email",
                    required: true,
                    autocomplete: "email",
                    half: true
                },
                {
                    label: "Téléphone",
                    name: "phone",
                    type: "tel",
                    required: true,
                    autocomplete: "tel",
                    half: true
                },
                {
                    label: "Êtes-vous un.e employé.e du groupe?* ",
                    name: "group",
                    type: "boolean",
                    required: true
                },
                {
                    title: "Demande"
                },
                {
                    label: "Organisation ou entreprise",
                    name: "organization",
                    type: "text",
                    required: false,
                    half: true
                },
                {
                    label: "Demande pour quel magasin ?",
                    name: "store",
                    options: [],
                    as: "title:title",
                    required: true,
                    half: true
                },
                {
                    label: "Date de l’événement",
                    name: "date",
                    type: "date",
                    required: false
                },
                {
                    label: "Description de la demande",
                    name: "body",
                    type: "textarea"
                },
                {
                    label: "Lien ou site internet",
                    name: "website",
                    type: "text"
                },
                {
                    label: "Comment avez-vous entendu parler du Groupe Boucher ?",
                    name: "aboutUs",
                    type: "textarea"
                },
                {
                    label: "Document complémentaire (PDF, JPG, PNG sont acceptés)",
                    type: "file",
                    name: "attachment[]"
                }
            ]
        };
    },
    computed: {
        ...mapState({
            forms: state => state.global.forms,
            stores: state => state.stores.elements
        })
    },
    created() {
        const idx = this.inputs.findIndex(f => f.name == "store");
        this.$set(this.inputs[idx], "options", this.stores);
    }
};
</script>

<style lang="scss">
.m-partnership {
    @media #{md("xs")} {
        width: calc(5 / 6 * 100%);
        margin-right: auto;
        margin-left: auto;
    }

    @media #{md("sm")} {
        width: calc(3 / 4 * 100%);
    }

    @media #{md("md")} {
        width: calc(2 / 3 * 100%);
    }
}

.m-partnership__title {
    margin-bottom: 1.5em;
    text-align: center;
}
</style>
