<template>
    <div :class="className">
        <span
            ref="bg"
            class="o-loader__bg"
        ></span>
        <div
            v-if="firstload"
            class="o-loader__inner"
            :style="`--logo-count:${banners.length}`"
        >
            <span class="o-loader__logo -big">
                <icon
                    icon="logo-gb-icon"
                    ref="gbIcon"
                    :cover="true"
                />
            </span>
            <span class="o-loader__logo -gb">
                <icon
                    icon="logo-gb"
                    :cover="true"
                    class="js-loader-logo"
                />
            </span>
            <span
                v-for="banner in banners"
                :key="`loader-icon-${banner.id}`"
                class="o-loader__logo"
            >
                <asset
                    :asset="banner.logo"
                    :lazy="false"
                    :contain="true"
                    class="js-loader-logo"
                />
            </span>
        </div>
    </div>
</template>


<script>

import Icon from 'objects/Icon'
import Asset from 'objects/Asset'

import { mapState, mapGetters } from 'vuex'
import { gsap } from 'gsap/all';

export default {
    name: 'Loader',
    components: {
        Icon,
        Asset,
    },
    data: () => ({
        loading: true,
    }),
    mounted() {
        this.$store.dispatch('loader/startLoad')

        this.$gbIcon = this.$refs.gbIcon.$el
        this.$logos = this.$el.querySelectorAll('.js-loader-logo')

        this.firstloadIn()
    },
    computed: {
        ...mapState({
            banners: state => state.global.banners,
            firstload: state => state.loader.firstload
        }),

        ...mapGetters({
            isLoading: 'loader/isLoading',
        }),

        className() {
            let classname = 'o-loader'

            if(this.firstload) {
                classname += ` -firstload`
            }

            if(this.loading) {
                classname += ' is-loading'
            }

            return classname
        },
    },
    methods: {
        firstloadIn() {

            gsap.timeline()
                .to(this.$gbIcon, {
                    duration: .8,
                    y: 0,
                    rotationZ: 0,
                    ease: 'power4.inOut',
                })
                .to(this.$refs.bg, {
                    duration: .8,
                    scaleY: 1,
                    ease: 'power4.in',
                })
                .set(this.$gbIcon, {
                    display: 'none',
                })
                .to(this.$logos, {
                    duration: .6,
                    y: 0,
                    rotationZ: 0,
                    stagger: {
                        each: .15,
                    },
                    ease: 'power3.out',
                    onComplete: () => {
                        this.$store.dispatch('loader/endLoad')
                    }
                })
        },

        firstloadOut() {

            this.setScroll()

            gsap.timeline()
                .to(this.$logos, {
                    duration: .4,
                    yPercent: 101,
                    stagger: {
                        each: .1,
                        from: 'end',
                    },
                    ease: 'power3.in'
                })
                .to(this.$refs.bg, {
                    duration: .8,
                    scaleY: 0,
                    borderTopRightRadius: '50vmax',
                    borderTopLeftRadius: '50vmax',
                    transformOrigin: '50% 100%',
                    ease: 'power4.inOut',
                    delay: -.2,
                    onComplete: () => {
                        this.$store.dispatch('loader/firstLoaded')
                        this.loading = false
                    }
                })
        },

        animateIn() {

            this.loading = true
            // this.$emit('enter')
            this.$store.dispatch('loader/startLoad')

            gsap.timeline({
                    onComplete: () => {
                        this.$store.dispatch('loader/endLoad')
                        // this.$emit('enterEnd')
                    }
                })
                .set(this.$refs.bg, {
                    borderTopRightRadius: 0,
                    borderTopLeftRadius: 0,
                    borderBottomRightRadius: '50vmax',
                    borderBottomLeftRadius: '50vmax',
                })
                .to(this.$refs.bg, {
                    duration: .8,
                    scaleY: 1,
                    borderBottomRightRadius: 0,
                    borderBottomLeftRadius: 0,
                    transformOrigin: '50% 0%',
                    ease: 'power4.inOut',
                })
        },
        animateOut() {

            this.loading = false
            // this.$emit('leave')

            this.setScroll()

            gsap.timeline({
                    // onComplete: () => {
                    //     this.$emit('leaveEnd')
                    // }
                })
                .to(this.$refs.bg, {
                    duration: .8,
                    scaleY: 0,
                    borderTopRightRadius: '50vmax',
                    borderTopLeftRadius: '50vmax',
                    transformOrigin: '50% 100%',
                    ease: 'power4.inOut',
                })
        },
        setScroll() {

            // Scroll to hash if exists
            const hash = this.$route.hash
            if (hash) {
                location.href = hash
            } else {
                window.scrollTo(0, 0)
            }

        }
    },
    watch: {
        isLoading(loading) {
            if(loading) {
                this.animateIn()
            } else if(this.firstload){
                this.firstloadOut()
            } else {
                this.animateOut()
            }
        }
    },
}

</script>

<style lang="scss">

.o-loader {
    z-index: -10;
    position: fixed;
    top: 0;
    left: 200vw;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    transition: z-index 0s 1s, left 0s 1s;

    &.-firstload {
        background-color: $color-light;

        .o-loader__bg {
            border-bottom-right-radius: 0;
        }
    }

    &.is-loading {
        z-index: 500;
        left: 0;
        transition: none;
    }
}

.o-loader__bg {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: $color-primary;
    transform: scale(1, 0);
    transform-origin: 50% 100%;
    will-change: transform;
}

.o-loader__inner {
    display: grid;
    width: 100%;
    max-width: calc(30em + (var(--logo-count) * 2em)) ; // var(--logo-count)
    padding: var(--grid-gutter);
    grid-template-columns: repeat(var(--logo-count), minmax(0, 1fr));
    grid-gap: 2em 1em;
    color: $color-light;

    @media #{md("sm")} {
        grid-gap: 4em 2em;
    }
}

.o-loader__logo {
    display: flex;
    align-items: flex-end;
    overflow: hidden;

    .o-icon,
    .o-asset {
        transform: translate(0, 75%) rotate3d(0, 0, 1, 25deg);
        transform-origin: 0 100%;
        will-change: transform;
        margin: 1px;
    }

    .o-asset {
        padding: 3px;
    }

    &.-big {

        position: absolute;
        top: calc(50% - (var(--svg-ratio) * var(--svg-cover-width)));
        left: 25%;
        width: 50%;
        color: $color-primary;
    }

    &.-gb {
        grid-column-start: 1;
        grid-column-end: calc( var(--logo-count) + 1);

        .o-icon {
            --color-icon-picto: currentColor;
        }
    }

    .o-asset {
        filter: invert(1);
    }
}

</style>
