<template>
    <section class="l-section">
        <span
            class="l-section__bg"
            :class="{ '-primary' : bg }"
        >
            <slot name="bg" />
        </span>
        <div class="l-section__inner | grid">
            <header
                v-if="!!$slots.header || title || desc"
                class="l-section__header | t-content"
            >
                <slot name="header">
                    <anim-text
                        v-if="title"
                        :text="title"
                        tag="h2"
                        class="t-t3"
                    />
                    <anim-text
                        v-if="desc"
                        :text="desc"
                        :cms="true"
                    />
                </slot>
            </header>
            <slot></slot>
        </div>
    </section>
</template>

<script>

export default {
    name: 'AppSection',
    props: {
        bg: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: null,
        },
        desc: {
            type: String,
            default: null,
        },
    },
};

</script>

<style lang="scss">

.l-section {
    --section-padding: #{vh(7)};

    html.is-mobile-safari & {
        --section-padding: 2rem;
    }

    padding-top: var(--section-padding);
    padding-bottom: var(--section-padding);
}

.l-section__bg {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;

    &.-primary {
        background-color: $color-primary;

        & + .l-section__inner {
            color: $color-light;
        }
    }
}

.l-section__inner {}

.l-section__header {
    margin-bottom: 2em;
}

</style>
