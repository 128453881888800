<template>
    <div>
        <hero
            :title="page.title"
            :intro="page.intro"
            :image="page.image"
            display="image-top"
        >
            <template #after-image>
                <deco
                    class="p-articles__deco"
                    field="baseball"
                    :ball="true"
                />
            </template>
        </hero>

        <section-list
            v-if="sections.length"
            :sections="sections"
        />
    </div>
</template>

<script>

import Hero from 'layout/Hero';
import Deco from 'objects/Deco';
import SectionList from 'components/SectionList';

import { SECTION_HANDLES, MODAL_COMPONENTS } from 'src/constants'

export default {
    name: 'Articles',
    components: {
        Hero,
        Deco,
        SectionList
    },
    props: {
        page: Object,
    },
    metaInfo() {
        return {
            title: this.page.seo ? this.page.seo.title : null
        }
    },
    data: () => ({
        articles: []
    }),
    created() {

        // Load job and open modal
        if (this.isArticle) {

            // Load store data
            this.$store.dispatch('articles/loadSingle', this.$route.params.slug)
                .then(job => {
                    this.$store.dispatch('modal/open', { component: MODAL_COMPONENTS.ARTICLE, data: job })
                })
        } else {
            this.$store.dispatch('articles/preloadAll').then(articles => {
                this.articles = articles
            })

        }
    },
    computed: {
        isArticle() {
            return this.$route.meta.section === SECTION_HANDLES.ARTICLE
        },

        sections() {
            const sections = []
            const years = [...new Set(this.articles.map(article => article.year))]

            // Force sort order in case an older article is up the list
            years.sort((a, b) => b - a);

            years.forEach(year => {
                const articlesFromYear = this.articles.filter(article => article.year === year)

                if(articlesFromYear.length > 0) {
                    sections.push({
                        title: year,
                        id: year,
                        cards: articlesFromYear
                    })
                }
            })

            return sections
        },
    }
};

</script>

<style lang="scss">

.p-articles {
    .l-section[data-index="0"] { padding: 0}
}

.p-articles__deco {
    --deco-size: 28vw;

    left: calc(-.5 * var(--grid-start-x));
    bottom: calc(-1.5 * var(--grid-start-x));
    transform: rotate(180deg);

    @media #{md("lg")} {
        --deco-size: 24em;
        bottom: vh(-10);
    }
}

</style>
