/*
 * Craft GraphQL Snippets
 */

import { getAsset } from "./assets";

/**********************************
 *
 *
 *  DEFAULT
 *
 *
 **********************************/

// Default data
const defaultData = `
id
url
`;

// Default entry
const entry = `
id
uri
sectionHandle
`;

// Default entry
const hero = (type = "header.top") => `
title
intro
${getAsset("image", type)}
`;

// Address field
const address = `
address {
    ...on address_BlockType {
        label
        googleMap
    }
}
`;

// Seo
const seo = `
seo {
    title
    description
}
`;

/**********************************
 *
 *
 *  ENTRIES
 *
 *
 **********************************/

// Pages

const pages = `
    title
    ${entry}
    slug
    order: lft
    ...on pages_pages_Entry {
        ${hero("header.top")}
        body
        ${seo}
    }
`;
// Store preload
const storePreload = `
    title
    ${entry}
    slug
    order: lft
    ...on store_store_Entry {
        titleAlt: heading2
        ${getAsset("image", "card.store")}
        cover: ${getAsset("image", "header.modal")}
        banners: selectEntriesBanner {
            id
            title
        }
        region: selectCategoryRegions {
            id
            title
        }
        relatedJobs {
            id
        }
        phone
        notes
        privilege: outerlink
    }
`;

// Store
const store = `
    title
    ${entry}
    slug
    order: lft
    ...on store_store_Entry {
        titleAlt: heading2
        ${getAsset("image", "card.store")}
        cover: ${getAsset("image", "header.modal")}

        banners: selectEntriesBanner {
            id
            title
        }
        overrideBanners: trueOrFalse2
        ${getAsset("logos")}

        region: selectCategoryRegions {
            id
            title
        }
        ${address}
        phone
        hideOpeningHours: trueOrFalse
        openingHours {
            day
            hours
        }
        notes

        # Manager
        manager
        role: label
        email

        # Privilege zone
        privilege: outerlink
        overridePrivilege: trueOrFalse3
        privilegeLabel: heading1
        privilegeText: body1

        # Social media
        facebook
        instagram

        relatedJobs {
            id
        }
        ${seo}
    }
`;

// Article partial
const articlePreload = `
    title
    ${entry}
    slug
    type: typeHandle
    datePost: postDate @formatDateTime
    dateUpdated @formatDateTime

    ...on article_news_Entry {
        ${getAsset("image", "card.article")}
        category: selectCategoryArticleCategories {
            title
        }
        body
    }
`;

// Article
const article = `
    title
    ${entry}
    slug
    type: typeHandle
    datePost: postDate @formatDateTime
    dateUpdated @formatDateTime

    ...on article_news_Entry {
        ${getAsset("image", "card.article")}
        category: selectCategoryArticleCategories {
            title
        }
        cover: ${getAsset("image", "header.modal")}
        body
        ${seo}
    }
`;

// Job preload
const jobPreload = `
    title
    ${entry}
    slug
    ...on job_job_Entry {
        store: selectEntryStore {
            id
        }
        employmentType
        department: selectCategoryJobDepartments {
            title
        }
        cardImage: ${getAsset("image2")}
    }
`;

// Job
const job = `
    title
    ${entry}
    slug
    datePost: postDate @formatDateTime
    dateUpdated @formatDateTime
    dateExpiration: expiryDate @formatDateTime

    ...on job_job_Entry {
        refNumber
        dateBegin @formatDateTime
        store: selectEntryStore {
            id
            ...on store_store_Entry {
                cover: ${getAsset("image", "header.modal")}
            }
        }
        employmentType
        department: selectCategoryJobDepartments {
            title
        }
        tasks
        conditions
        cover: ${getAsset("image", "header.modal")}
        cardImage: ${getAsset("image2")}
        ${seo}
    }
`;

//
export default {
    seo,
    defaultData,
    entry,
    hero,
    address,

    pages,

    storePreload,
    store,

    articlePreload,
    article,

    jobPreload,
    job
};
