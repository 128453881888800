<template>
    <div :class="className">
        <p class="o-input-boolean__label" v-html="label" />
        <div class="o-input-boolean__inputs">
            <input
                :id="`input-${_uid}-true`"
                type="radio"
                :value="true"
                ref="input"
                v-model="value"
                :required="required"
            />
            <label :for="`input-${_uid}-true`">
                Oui
            </label>
            <input :id="`input-${_uid}-false`" type="radio" :value="false" ref="input" v-model="value" />
            <label :for="`input-${_uid}-false`">
                Non
            </label>
        </div>
    </div>
</template>

<script>
export default {
    name: "AppInputBoolean",
    props: {
        label: {
            type: String,
            default: null
        },
        required: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        defaultValue: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        value: ""
    }),
    mounted() {
        this.value = this.defaultValue;
    },
    computed: {
        className() {
            let className = "o-input-boolean";
            className += this.disabled ? " is-disabled" : "";

            return className;
        }
    },
    methods: {
        reset() {
            this.value = "";
        }
    },
    watch: {
        value(val) {
            this.$emit("input", val);
        }
    }
};
</script>

<style lang="scss">
.o-input-boolean {
    --border: 1px solid var(--input-border-color);

    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.o-input-boolean__label {
    a {
        text-decoration: underline;
    }
    & + .o-input-boolean__inputs {
        margin-left: 1.5em;
    }
}

.o-input-boolean__inputs {
    display: flex;
    flex-shrink: 0;
    flex-grow: 0;
    color: var(--input-border-color);
    border: var(--border);
    border-radius: var(--input-border-radius);
    overflow: hidden;

    input {
        display: none;

        &:checked + label {
            color: var(--input-checked-color-text);
            background-color: var(--input-checked-color-bg);
        }
    }

    label {
        padding: 0.6em 1.2em;

        &:last-child {
            border-left: var(--border);
        }
    }
}
</style>
