/*
 * Craft asset transform w/ srcset
 *
 */

const transforms = [
    {
        name: 'square',
        mode: 'crop',
        width: 500,
        height: 500,
        quality: 100,
        position: 'center-center'
    },
    {
        name: 'header.home',
        mode: 'crop',
        width: 1380,
        height: 900,
        quality: 90,
        position: 'center-center',
        srcset: `["336w", "350w", "672w", "700w", "768w", "725w", "971w", "1346w", "1394w", "2692w", "2788w"]`
    },
    {
        name: 'header.top',
        mode: 'crop',
        width: 1320,
        height: 527,
        quality: 90,
        position: 'center-center',
        srcset: `["311w", "325w", "683w", "723w", "918w", "1252w", "1320w", "2304w", "2640w"]`
    },
    {
        name: 'header.side',
        mode: 'crop',
        width: 710,
        height: 770,
        quality: 90,
        position: 'center-center',
        srcset: `["336w", "350w", "520w", "720w", "768w", "1040w", "1440w", "1536w"]`
    },
    {
        name: 'header.modal',
        mode: 'crop',
        width: 1275,
        height: 715,
        quality: 90,
        position: 'center-center',
        srcset: `["279w", "330w", "1110w", "1204w", "1250w", "2400w"]`
    },
    {
        name: 'card.default',
        mode: 'crop',
        width: 314,
        height: 470,
        quality: 90,
        position: 'center-center',
        srcset: `["270w", "350w", "520w", "620w", "700w"]`
    },
    {
        name: 'card.article',
        mode: 'crop',
        width: 270,
        height: 155,
        quality: 90,
        position: 'center-center',
        srcset: `["271w", "340w", "521w", "540w", "680w"]`
    },
    {
        name: 'card.store',
        mode: 'crop',
        width: 265,
        height: 315,
        quality: 90,
        position: 'center-center',
        srcset: `["270w", "341w", "540w", "682w"]`
    },
    {
        name: 'landscape.3:2',
        mode: 'crop',
        width: 1320,
        height: 884,
        quality: 90,
        position: 'center-center',
        srcset: `["330w", "345w", "660w", "690w", "710w", "1280w", "1420w", "2560w"]`
    },
    {
        name: 'landscape.2:1',
        mode: 'crop',
        width: 1010,
        height: 570,
        quality: 90,
        position: 'center-center',
        srcset: `["310w", "325w", "596w", "620w", "650w", "920w", "992w", "1840w"]`
    }
]

export const getAsset = (field = 'image', format = false) => {

    let transform

    const data = `
        url
        title
        width
        height
    `

    if ( format && typeof format == 'string' ) {
        transform = transforms.find(t => t.name == format)
    }

    if ( transform ) {

        if (transform.srcset) {
            return `
                ${field} @ transform(mode: "${transform.mode}", width: ${transform.width}, height: ${transform.height||0}, quality: ${transform.quality||80}, position: "${transform.position||'center-center'}", immediately: false) {
                    ${data}
                    mimeType
                    srcset (sizes: ${transform.srcset})
                }
            `
        } else {

            return `
                ${field} @ transform(mode: "${transform.mode}", width: ${transform.width}, height: ${transform.height||0}, quality: ${transform.quality||80}, position: "${transform.position||'center-center'}", immediately: false) {
                    ${data}
                    mimeType
                }
            `
        }

    } else {

        return `
            ${field} {
                ${data}
            }
        `
    }
}

export default {
    getAsset
}
