<template>
    <span
        v-if="field"
        :class="className"
    >
        <span class="o-deco__inner">
            <icon :icon="`field-${field}`" />
            <span
                v-if="ball"
                class="o-deco__ball"
            ></span>
        </span>
    </span>
</template>

<script>

import Icon from 'objects/Icon';

export default {
    name: 'Deco',
    components: {
        Icon,
    },
    props: {
        field: {
            type: String,
            default: null,
        },
        ball: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        className() {
            let classname = 'o-deco'

            classname += ` -${this.field}`

            return classname
        },
    }
};

</script>

<style lang="scss">

.o-deco {
    --deco-size: 10em;

    position: absolute;
    display: block;
    width: var(--deco-size);
    overflow: hidden;

    .o-icon {
        --svg-color: var(--deco-color, #{$color-primary});
        --svg-width: var(--deco-size);
    }

    &.-hockey {

        .o-deco__ball {
            bottom: 0%;
            left: 30%;
        }
    }

    &.-basketball {

        .o-deco__ball {
            top: 0%;
            right: 30%;
        }
    }

    &.-baseball {

        .o-deco__ball {
            --deco-ball-size: calc(var(--deco-size)/8);
            top: 60%;
            left: 0;
        }
    }
}

.o-deco__inner {
    display: block;
    width: var(--deco-size);
}

.o-deco__ball {
    --deco-ball-size: calc(var(--deco-size)/15);
    position: absolute;
    display: block;
    width: var(--deco-ball-size);
    height: var(--deco-ball-size);
    border-radius: 50%;
    background-color: var(--deco-ball-color, #{$color-orange});
}

</style>
