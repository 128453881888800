<template>
    <div v-if="cards.length > 0" :class="className">
        <slider
            v-if="sliderIsActive"
            :options="sliderOpts"
            :slides="cards"
            :prevNext="prevNext"
            :fullwidth="fullwidth"
            :fullheight="true"
            ref="slider"
            class="c-card-grid__grid"
        >
            <template #slide="card">
                <card :data="card" />
            </template>
        </slider>

        <div v-else class="c-card-grid__grid" ref="grid">
            <card v-for="(card, i) in cards" :data="card" ref="card" :key="`card-grid-${i}`" />
        </div>
    </div>
</template>

<script>
import Slider from "components/Slider";
import Card from "components/Card";

import Variables from "src/mixins/variables";

export default {
    name: "CardGrid",
    components: {
        Slider,
        Card,
    },
    mixins: [Variables],
    props: {
        cards: {
            type: Array,
            default: () => [],
        },
        slider: {
            type: Boolean,
            default: false,
        },
        prevNext: {
            type: Boolean,
            default: true,
        },
        fullwidth: {
            type: Boolean,
            default: true,
        },
    },
    data: () => ({
        sliderIsActive: false,
        sliderOpts: {
            watchCSS: true,
            groupCells: true,
        },
        windowWidth: 0,
    }),
    created() {
        this.windowWidth = this.W.w;
    },
    mounted() {
        if (this.slider) {
            this.setSlider();
            window.addEventListener("resize", this.resize);
        }
    },
    computed: {
        className() {
            let classname = "c-card-grid";

            classname += this.sliderIsActive ? " -slider" : " -no-slider";

            return classname;
        },
    },
    methods: {
        setSlider() {
            this.sliderIsActive = false;
            this.cellsHeight = false;

            this.$nextTick(() => {
                const gridRows = window
                    .getComputedStyle(this.$refs.grid)
                    .getPropertyValue("grid-template-rows")
                    .split(" ").length;

                if (gridRows > 1) {
                    this.sliderIsActive = true;
                }
            });
        },
        resize() {
            setTimeout(() => {
                // Check if its a real resize
                if (this.windowWidth != this.W.w) {
                    this.windowWidth = this.W.w;
                    this.setSlider();
                }
            }, 75);
        },
    },
    beforeDestroy() {
        if (this.slider) {
            window.removeEventListener("resize", this.resize);
        }
    },
};
</script>

<style lang="scss">
.c-card-grid {
    --grid-auto-size: auto-fill;
    --grid-min-width: 18em;
    margin-top: 2em;

    &.-no-slider {
        .c-card-grid__grid {
            display: grid;
            grid-gap: 1em var(--grid-gutter-half);
            grid-template-columns: repeat(var(--grid-auto-size), minmax(var(--grid-min-width), 1fr));
            justify-content: center;
            overflow-anchor: none;
        }
    }

    &.-slider {
        .c-slider {
            --slide-width: 18em;
        }

        .c-slider__inner:after {
            content: "flickity";
            display: none;
        }

        .c-card {
            width: 100%;
            height: calc(var(--slide-height) - 1px);
            max-width: 18em;

            &:not(:first-child) {
                margin-left: 1em;
            }
        }
    }

    @media #{md("sm")} {
        &.-no-slider {
            .c-card-grid__grid {
                grid-row-gap: vh(7);

                html.is-mobile-safari & {
                    grid-row-gap: 2rem;
                }
            }
        }
    }
}
</style>
